import React, { useState } from "react";
import { Box, Grid, SvgIcon } from "@mui/material";
import "./footer.css";
import InstagramIcon from "@mui/icons-material/Instagram";

import logoDana from "../../../assets/logoDana.png";
import logoShopeePay from "../../../assets/logoShopeePay.svg";
import logoOvo from "../../../assets/logoOvo.png";
import logoLinkAja from "../../../assets/logoLinkAja.png";
import logoJeniusPay from "../../../assets/logoJeniusPay.png";
import logoAstraPay from "../../../assets/logoAstraPay.png";
import logoBca from "../../../assets/bca.png";
import logoMandiri from "../../../assets/mandiri.png";
import logoBri from "../../../assets/bri.svg";
import logoBni from "../../../assets/bni.svg";
import logoCimb from "../../../assets/cimb.svg";
import logoPermata from "../../../assets/permata.svg";
import logoSampoerna from "../../../assets/sampoerna.svg";
import logoBjb from "../../../assets/bjb.svg";
import logoBsi from "../../../assets/bsi.svg";
import logoQris from "../../../assets/qris.svg";

const Footer = () => {
  return (
    <>
      <Box padding={5} sx={{ background: "black", color: "white" }}>
        <Grid container spacing={3} alignItems={"center"}>
          <Grid item xs={12} sm={12} md={4} lg={6}>
            <Grid container className="footer-media">
              <Grid item xs={1} textAlign="center">
                <InstagramIcon />
              </Grid>
              <Grid item xs={1} textAlign="center">
                <SvgIcon color="white">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    {" "}
                    <path fill="white" d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
                  </svg>
                </SvgIcon>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={8} lg={6}>
            <Grid container className="footer-payment" spacing={1}>
              <Grid item xs={1} textAlign="center">
                <img src={logoDana} alt="logo dana" width="100%"></img>
              </Grid>
              <Grid item xs={1} textAlign="center">
                <img src={logoShopeePay} alt="logo ShopeePay" width="100%"></img>
              </Grid>
              <Grid item xs={1} textAlign="center">
                <img src={logoOvo} alt="logo Ovo" width="100%"></img>
              </Grid>
              <Grid item xs={1} textAlign="center">
                <img src={logoLinkAja} alt="logo LinkAja" width="100%"></img>
              </Grid>
              <Grid item xs={1} textAlign="center">
                <img src={logoJeniusPay} alt="logo JeniusPay" width="100%"></img>
              </Grid>
              <Grid item xs={1} textAlign="center">
                <img src={logoAstraPay} alt="logo AstraPay" width="100%"></img>
              </Grid>
              <Grid item xs={1} textAlign="center">
                <img src={logoBca} alt="logo Bca" width="100%"></img>
              </Grid>
              <Grid item xs={1} textAlign="center">
                <img src={logoMandiri} alt="logo Mandiri" width="100%"></img>
              </Grid>
              <Grid item xs={1} textAlign="center">
                <img src={logoBri} alt="logo Bri" width="100%"></img>
              </Grid>
              <Grid item xs={1} textAlign="center">
                <img src={logoBni} alt="logo Ovo" width="100%"></img>
              </Grid>
              <Grid item xs={1} textAlign="center">
                <img src={logoCimb} alt="logo Cimb" width="100%"></img>
              </Grid>
              <Grid item xs={1} textAlign="center">
                <img src={logoPermata} alt="logo Permata" width="100%"></img>
              </Grid>
              <Grid item xs={1} textAlign="center">
                <img src={logoSampoerna} alt="logo SamplogoSampoerna" width="100%"></img>
              </Grid>
              <Grid item xs={1} textAlign="center">
                <img src={logoBjb} alt="logo Bjb" width="100%"></img>
              </Grid>
              <Grid item xs={1} textAlign="center">
                <img src={logoBsi} alt="logo Bsi" width="100%"></img>
              </Grid>
              <Grid item xs={1} textAlign="center">
                <img src={logoQris} alt="logo Qris" width="100%"></img>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Footer;
