import React, { useState } from 'react';
import StockSortableItem from './stockSortableItem';
import { Grid } from '@mui/material';

function StockSortableList({ stockItems }) {
  const [items, setItems] = useState(stockItems);

  const moveItem = (dragIndex, hoverIndex) => {

    const updatedItems = [...items];
    const [movedItem] = updatedItems.splice(dragIndex, 1);
    updatedItems.splice(hoverIndex, 0, movedItem);

    updatedItems[dragIndex].showOrder = dragIndex + 1;
    updatedItems[dragIndex].updated = true;
    updatedItems[hoverIndex].showOrder = hoverIndex + 1;
    updatedItems[hoverIndex].updated = true;

    setItems(updatedItems);
  };

  return (
    <>
      <Grid container rowSpacing={2}>
        {
          items.map((item, index) => (
            <StockSortableItem 
              key={item.id}
              id={item.id}
              index={index}
              stockItem={item}
              moveItem={moveItem}
            />
          ))
        }
      </Grid>
    </>
  )
}

export default StockSortableList;