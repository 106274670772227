import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Container, Grid, Button, Alert, Tabs, Tab } from "@mui/material";
import CustomerService from "../services/customer/customerService";
import Navigation from "./navigation";
import ImageCarousel from "./components/carousel/carousel";
import ItemCard from "./components/itemCard/itemCard";
import BrandCard from "./components/itemCard/BrandCard";
import TagService from "../services/manager/tagService";
import Footer from "./components/footer/footer";
import CarouselText from "./components/carousel/caroutselText";
import BannerService from "../services/manager/bannerService";
import BannerCarousel from "./components/carousel/carouselBanner";

const tabStyle = {
  "&.Mui-selected": {
    color: "white",
    backgroundColor: "#FE8A01",
    color: "white",
    variant: "h6",
  },
  "&:hover": {
    color: "white",
    backgroundColor: "#FE8A01",
    color: "white",
    variant: "h6",
  },
  backgroundColor: "white",
  color: "black",
};

export default function HomePage() {
  const [ShowSuccess, setShowSuccess] = useState(false);
  const [MsgSuccess, setMsgSuccess] = useState("");
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState();
  const [newRelease, setNewRelease] = useState([]);
  const [hotSale, setHotSale] = useState([]);
  const [products, setProducts] = useState([]);
  const [bannner, setBanner] = useState([]);
  const [tagMainList, setTagMain] = useState([]);
  const [tagSecondaryList, setTagSecondary] = useState([]);
  const [shownTagItem, setShownTagItem] = useState([]);
  const [tabValue, setTabValue] = useState(null);
  const [banners, setBanners] = useState([]);
  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getAllTag = async () => {
    try {
      const response = await TagService.getShownTagList("");
      if (response) {
        const data = response.data.tagModelList;
        setTagMain(data.filter((tag) => tag.flag === "main"));
        setTagSecondary(data.filter((tag) => tag.flag === "secondary"));
      }
    } catch (error) {
      console.error(error);
      setMsgError("Error while fetching list tags");
      activateError();
    }
  };

  async function fetchItems() {
    try {
      const responseProducts = await CustomerService.getListItem(tabValue);
      const responseBanner = await CustomerService.getListItem();

      setProducts(responseProducts.data.itemList.slice(0, 6));
      setBanner(responseBanner.data.itemList.slice(0, 6));

      const tagFiltered = tagSecondaryList.slice(0, 3);
      const shownData = await Promise.all(
        tagFiltered.map(async (tag) => {
          const responseItem = await CustomerService.getListItem(
            tabValue,
            tag.id
          );
          return { ...tag, items: responseItem.data.itemList.slice(0, 12) };
        })
      );
      setShownTagItem(shownData);
    } catch (error) {
      console.error(error);
    }
  }

  const getAllBanner = async () => {
    try {
      const response = await BannerService.getAllBanner();
      if (response) {
        const data = response.data.outputSchema;
        setBanners(data);
      }
    } catch (error) {
      console.error(error);
      setMsgError("Error while fetching list banners");
      activateError();
    }
  }

  useEffect(() => {
    getAllTag();
    getAllBanner();
  }, []);

  useEffect(() => {
    if (tagMainList.length > 0) {
      setTabValue(tagMainList?.[0]?.id);
    }
  }, [tagMainList]);

  useEffect(() => {
    if (tabValue != null) {
      fetchItems();
    }
  }, [tabValue]);

  useEffect(() => {
    console.log(shownTagItem);
  }, [shownTagItem]);

  const activateSuccess = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 5000);
  };

  const activateError = () => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 5000);
  };

  const btnMoreStyle = {
    borderRadius: "100px",
    width: "250px",
    maxWidth: "100%",
    height: "50px",
    backgroundColor: "#FE8A01",
    color: "black",
    padding: 5,
    textTransform: "none",
    fontSize: "1em",
  };

  return (
    <>
      <Navigation />

      <Container component="main" sx={{ paddingBottom: "50px", minHeight: "80vh" }}>
        {ShowSuccess && (
          <Alert variant="filled" severity="success" style={{ marginTop: 20 }}>
            {MsgSuccess}
          </Alert>
        )}
        {showError && (
          <Alert variant="filled" severity="error" style={{ marginTop: 20 }}>
            {msgError}
          </Alert>
        )}

        <Box sx={{ marginTop: "20px" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="scrollable" // Enable scrollable behavior
            scrollButtons="auto" // Show scroll buttons if tabs overflow
            allowScrollButtonsMobile // Allow scroll buttons to appear on mobile
            sx={{
              "& .MuiTabs-scroller": {
                overflowY: "hidden", // Disable vertical scrolling
                overflowX: "auto", // Enable horizontal scrolling only
              },
              "& .MuiTabs-flexContainer": {
                flexWrap: "nowrap", // Prevent tabs from wrapping into multiple lines
                justifyContent: "center",
              },
              "& .MuiTab-root": {
                minWidth: "120px", // Adjust tab width if necessary
                whiteSpace: "nowrap", // Prevent text wrapping in tab headers
              },
            }}
          >
            {tagMainList.map((tag) => (
              <Tab
                key={tag.id}
                value={tag.id}
                label={tag.title}
                sx={tabStyle}
              />
            ))}
          </Tabs>
        </Box>
        
        {
          banners[0] ? (
            <Grid container marginTop={5} justifyContent="center">
              <Grid item xs={12}>
                <CarouselText texts={banners[0]?.runningText} />
              </Grid>
            </Grid>
          ) : null
        }

        {
          banners[1] ? (
            <Grid container marginTop={5} marginBottom={2} justifyContent="center">
              <Grid item xs={12} md={8}>
                <BannerCarousel images={banners[1]?.images || []} 
                  title={banners[1]?.title}
                  description={banners[1]?.description}
                  navigateTo={`/items/more?tagMain=${tabValue}&tagSecondary=${banners[1]?.tagSecondary.id}`} />
              </Grid>
            </Grid>
          ) : null
        }

        {shownTagItem[0] ? (
          <Grid container marginTop={5}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight="bold" color={"#FE8A01"} textTransform="uppercase">
                {shownTagItem[0].title}
              </Typography>
            </Grid>

            {shownTagItem[0].items.length <= 0 ? (
              <Grid item xs={12} textAlign="center" marginTop={3}>
                <Typography variant="h6" fontWeight="bold" color={"#FE8A01"}>
                  No item found
                </Typography>
              </Grid>
            ) : (
              <>
                {shownTagItem[0].items.map((item) => (
                  <ItemCard key={item.id} item={item} />
                ))}

                <Grid
                  item
                  xs={12}
                  marginTop={3}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    style={btnMoreStyle}
                    onClick={() => navigate(`/items/more?tagMain=${tabValue}&tagSecondary=${shownTagItem[0].id}`)}
                  >
                    TAP FOR MORE
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        ) : null}

        {
          banners[2] ? (
            <Grid container marginTop={5} marginBottom={5} justifyContent="center">
              <Grid item xs={12} md={8}>
                <BannerCarousel images={banners[2]?.images || []} 
                  title={banners[2]?.title}
                  description={banners[2]?.description}
                  navigateTo={`/items/more?tagMain=${tabValue}&tagSecondary=${banners[2]?.tagSecondary.id}`} />
              </Grid>
            </Grid>
          ) : null
        }

        {shownTagItem[1] ? (
          <Grid container marginTop={5}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight="bold" color={"#FE8A01"} textTransform="uppercase">
                {shownTagItem[1].title}
              </Typography>
            </Grid>

            {shownTagItem[1].items.length <= 0 ? (
              <Grid item xs={12} textAlign="center" marginTop={3}>
                <Typography variant="h6" fontWeight="bold" color={"#FE8A01"}>
                  No item found
                </Typography>
              </Grid>
            ) : (
              <>
                {shownTagItem[1].items.map((item) => (
                  <ItemCard key={item.id} item={item} />
                ))}

                <Grid
                  item
                  xs={12}
                  marginTop={3}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    style={btnMoreStyle}
                    onClick={() => navigate(`/items/more?tagMain=${tabValue}&tagSecondary=${shownTagItem[1].id}`)}
                  >
                    TAP FOR MORE
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        ) : null}

        {shownTagItem[2] ? (
          <Grid container marginTop={5}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight="bold" color={"#FE8A01"} textTransform="uppercase">
                {shownTagItem[2].title}
              </Typography>
            </Grid>

            {shownTagItem[2].items.length <= 0 ? (
              <Grid item xs={12} textAlign="center" marginTop={3}>
                <Typography variant="h6" fontWeight="bold" color={"#FE8A01"}>
                  No item found
                </Typography>
              </Grid>
            ) : (
              <>
                {shownTagItem[2].items.map((item) => (
                  <ItemCard key={item.id} item={item} />
                ))}

                <Grid
                  item
                  xs={12}
                  marginTop={3}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    style={btnMoreStyle}
                    onClick={() => navigate(`/items/more?tagMain=${tabValue}&tagSecondary=${shownTagItem[2].id}`)}
                  >
                    TAP FOR MORE
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        ) : null}

        <Grid container marginTop={5} marginBottom={5}>
          <Grid item xs={12}>
            <Typography variant="h4" fontWeight="bold" color={"#FE8A01"} textTransform="uppercase">
              All Product
            </Typography>
          </Grid>
          {products.map((item) => (
            <ItemCard key={item.id} item={item} />
          ))}
          <Grid
            item
            xs={12}
            marginTop={3}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              style={btnMoreStyle}
              onClick={() => navigate(`/items/more?tagMain=${tabValue}&showAll=true`)}
            >
              TAP FOR MORE
            </Button>
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </>
  );
}
