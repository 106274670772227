import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import noImage from "../../assets/no-image.png";
import { Box, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';

const ItemTypes = {
  ITEM: 'item',
};

function StockSortableItem({id, index, stockItem, moveItem}) {
  const ref = useRef(null);
  const imageUrl = "https://api.liquestore.com/assets/items/"

  const [, drop] = useDrop({
    accept: ItemTypes.ITEM,
    hover(item, monitor) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      // Get the bounding rectangle and vertical center of the item being hovered over
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move if the drag passes the middle of the item
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.ITEM,
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <>
      <Grid item xs={12} sx={{ opacity: isDragging ? 0.5 : 1 }}>
        <div ref={ref}>
          <Card sx={{ display: "flex" }}>
            <CardMedia 
              component="img"
              sx={{ aspectRatio: "5 / 4", width: "20%" }}
              src={ stockItem.files == null ? noImage : `${imageUrl}${stockItem.files[0]}` }
              alt={ stockItem.nama }
            />
            <Box sx={{ flexGrow: "1", alignSelf: "center" }}>
              <CardContent>
                <Typography component="div" variant="h5" textAlign="start">
                  { stockItem.nama }
                </Typography>
                <Typography variant="p" component="div" textAlign="start">
                  { stockItem.itemcode }
                </Typography>
              </CardContent>
            </Box>
          </Card>
        </div>
      </Grid>
    </>
  )
}

export default StockSortableItem;