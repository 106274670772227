import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "./navigation";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Checkbox,
  Container,
  Fade,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import noImage from "../../src/assets/no-image.png";
import { useAuth } from "../authContext";
import CartService from "../services/customer/cartService";
import DeleteIcon from "@mui/icons-material/Delete";
import Footer from "./components/footer/footer";

export default function CartPage() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const cleanedBackendUrl = backendUrl.replace("/backend", "");
  const [ShowSuccess, setShowSuccess] = useState(false);
  const [MsgSuccess, setMsgSuccess] = useState("");
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState();
  const [cartArr, setCartArr] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [openProceed, setOpenProceed] = useState(false);
  const [loadingProceed, setLoadingProceed] = useState(false);
  const { auth } = useAuth();
  const navigate = useNavigate();
  const imageUrl = "https://api.liquestore.com/assets/items/"

  const handleCloseDelete = () => {
    setDeleteId(null);
    setOpenDelete(false);
  };

  const handleOpenDelete = (cartId) => {
    setDeleteId(cartId);
    setOpenDelete(true);
  };

  const handleCloseProceed = () => {
    setOpenProceed(false);
  };

  const handleOpenProceed = (cartId) => {
    setOpenProceed(true);
  };

  const styleModalDelete = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    maxHeight: "80vh",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    textAlign: "center",
    overflowY: "auto",
  };

  const btnPrimaryStyle = {
    borderRadius: "15px",
    width: "100px",
    backgroundColor: "#FE8A01",
    color: "white",
    padding: 5,
    textTransform: "none",
    fontSize: "1em",
  };

  const btnWhiteStyle = {
    borderRadius: "15px",
    width: "100px",
    backgroundColor: "white",
    color: "#FE8A01",
    padding: 5,
    textTransform: "none",
    fontSize: "1em",
  };

  const btnDisabledStyle = {
    borderRadius: "15px",
    width: "100px",
    backgroundColor: "#dddddd",
    color: "#FE8A01",
    padding: 5,
    textTransform: "none",
    fontSize: "1em",
  };

  const btnRedStyle = {
    borderRadius: "15px",
    width: "100px",
    backgroundColor: "#E50B0B",
    color: "white",
    padding: 5,
    textTransform: "none",
    fontSize: "1em",
  };

  const checkboxStyle = {
    color: "#FE8A01",
    "&.MuiChecked": {
      color: "#FE8A01",
    },
  };

  const getCartList = async (userId) => {
    try {
      const result = await CartService.getCartList(userId);
      if (result) {
        setCartArr(result.data.cartList);
        calculatePrice();
      }
    } catch (error) {
      console.error(error);
      setMsgError("Something went wrong");
    }
  };

  const calculatePrice = () => {
    let price = 0;
    cartArr.forEach((cart) => {
      if (cart.checked) {
        price += cart.item.defaultPrice;
      }
    });

    setTotalPrice(price);
  };

  const changeCheckedStatus = async (cart) => {
    try {
      const checked = !cart.checked;
      cart.checked = checked;
      calculatePrice();

      await CartService.changeCartStatus(cart.id, checked);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteCart = async () => {
    setLoadingDelete(true);
    try {
      const result = await CartService.deleteCart(deleteId);
      if (result) {
        setCartArr(cartArr.filter((cart) => cart.id !== deleteId));
        calculatePrice();
        setMsgSuccess("Delete cart success");
      }
    } catch (error) {
      console.error(error);
      setMsgError("Something went wrong");
    } finally {
      setLoadingDelete(false);
      handleCloseDelete();
    }
  };

  const proceedToPayment = async () => {
    setLoadingProceed(true);
    try {
      const id = [];
      cartArr.forEach((cart) => {
        if (cart.checked) id.push(cart.id);
      });

      const result = await CartService.proceedToPayment(id);
      if (result) {
        // setCartArr(cartArr.filter((cart) => cart.id !== deleteId));
        // calculatePrice();
        const orderId = result.data?.[0].id
        navigate(`/customer/checkoutPage/${orderId}`);
        getCartList(auth?.user.id);
      }
    } catch (error) {
      console.error(error);
      setMsgError("Something went wrong");
    } finally {
      setLoadingProceed(false);
      handleCloseProceed();
    }
  };

  useEffect(() => {
    getCartList(auth?.user.id);
    window.scrollTo(0, 0);
  }, [auth]);

  useEffect(() => {
    calculatePrice();
  }, [cartArr]);

  return (
    <>
      <Navigation />

      <Container component="main" sx={{ marginBottom: "50px" }}>
        {ShowSuccess && (
          <Alert variant="filled" severity="success" style={{ marginTop: 20 }}>
            {MsgSuccess}
          </Alert>
        )}
        {showError && (
          <Alert variant="filled" severity="error" style={{ marginTop: 20 }}>
            {msgError}
          </Alert>
        )}

        <Grid container marginTop={5} columnSpacing={2}>
          <Grid item xs={12} marginBottom={3}>
            <Typography variant="h4" fontWeight="bold" color="#FE8A01">
              YOUR CART
            </Typography>
          </Grid>
        </Grid>

        <Grid container position="relative" minHeight="80vh">
          {cartArr.map((cart) => (
            <Grid item xs={12} sx={{ padding: "15px" }} key={cart.id}>
              <Grid container columnSpacing={3}>
                <Grid item xs={5} md={3}>
                  <img
                    src={
                      cart.item.images?.length <= 0 || cart.item.images == null
                        ? noImage
                        : `${imageUrl}${cart.item.images[0]}`
                    }
                    alt={cart.item.name}
                    style={{
                      width: "100%",
                      aspectRatio: "1 / 1",
                      objectFit: "cover",
                    }}
                  ></img>
                </Grid>
                <Grid
                  item
                  xs={5}
                  md={7}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "auto",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ marginBottom: "20px" }}>
                    <Typography variant="h5">{cart.item.name}</Typography>
                    <Typography variant="p" style={{ marginBottom: "10px" }}>
                      Size: {cart.item.size}
                    </Typography>
                  </div>
                  <Typography variant="h5" color="#FE8A01" fontWeight="bold">
                    <NumericFormat
                      value={cart.item.defaultPrice}
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix={"Rp. "}
                    />
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  display="flex"
                  justifyContent="end"
                  alignItems="center"
                >
                  {/* <Button style={btnRedStyle}>Remove</Button> */}
                  <IconButton
                    aria-label="remove"
                    onClick={() => handleOpenDelete(cart.id)}
                  >
                    <DeleteIcon style={{ color: "#E50B0B" }} />
                  </IconButton>
                  <Checkbox
                    style={checkboxStyle}
                    defaultChecked={cart.checked}
                    onChange={() => changeCheckedStatus(cart)}
                  ></Checkbox>
                </Grid>
              </Grid>
            </Grid>
          ))}

          <Grid
            item
            xs={12}
            sx={{
              background: "#FE8A01",
              padding: "25px",
              borderRadius: "100px",
              position: "sticky",
              bottom: "10px",
              alignSelf: "flex-end",
              boxShadow: "5px -2px 15px rgba(0, 0, 0, .3)",
            }}
          >
            <Grid container alignItems={"center"}>
              <Grid item xs={2}>
                <Typography variant="p" color="white">
                  Total
                </Typography>
              </Grid>
              <Grid item xs={6} sm={7}>
                <Typography variant="h6" fontWeight="bold" color="white">
                  <NumericFormat
                    value={totalPrice}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix={"Rp. "}
                  />
                </Typography>
              </Grid>
              <Grid item xs={4} sm={3} textAlign="end">
                <Button
                  style={
                    cartArr.filter((cart) => cart.checked).length <= 0
                      ? btnDisabledStyle
                      : btnWhiteStyle
                  }
                  disabled={cartArr.filter((cart) => cart.checked).length <= 0}
                  onClick={handleOpenProceed}
                >
                  Checkout
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Footer />

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openDelete}
        onClose={handleCloseDelete}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openDelete}>
          <Box sx={styleModalDelete}>
            <Typography id="spring-modal-title" variant="h6" component="h2">
              Apakah anda yakin ingin menghapus produk ini dari keranjang?
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Button
                sx={{ backgroundColor: "#E50B0B", color: "white" }}
                onClick={deleteCart}
                disabled={loadingDelete}
              >
                Hapus
              </Button>
              <Button
                variant="outlined"
                onClick={handleCloseDelete}
                sx={{
                  ml: 2,
                  backgroundColor: "#FE8A01",
                  color: "white",
                }}
              >
                Tidak
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openProceed}
        onClose={handleCloseProceed}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openProceed}>
          <Box sx={styleModalDelete}>
            <Typography id="spring-modal-title" variant="h6" component="h2">
              Apakah anda yakin ingin melakukan checkout?
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Button
                sx={{ backgroundColor: "#FE8A01", color: "white" }}
                onClick={proceedToPayment}
                disabled={loadingProceed}
              >
                Checkout
              </Button>
              <Button
                variant="outlined"
                onClick={handleCloseProceed}
                sx={{ ml: 2 }}
              >
                Tidak
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
