import React, { useEffect, useState } from 'react';
import './carousel.css'; 
import { Typography } from '@mui/material';

const CarouselText = ({texts}) => {

  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNext = () => {
    if (texts.length <= 0) {
      setCurrentIndex(0)
    } else {
      setCurrentIndex(currentIndex === texts.length - 1 ? 0 : currentIndex + 1);
    }
  };

  useEffect(() => {
    const interval = setInterval(goToNext, 8000);
    return () => clearInterval(interval); 
  }, [currentIndex]);

  return (
    <div className="carousel text">
      <div className="carousel-track-wrapper">
        <div
          className="carousel-track"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
          {texts.map((text, index) => (
            <div className='carousel-text-container' key={index}>
              <Typography variant='p' fontWeight="500" paddingX={3}>{text}</Typography>
            </div>
          ))}

          {
            texts.length <= 0 && (
              <div className='carousel-text-container'>
                <Typography variant='p' fontWeight="500" paddingX={3}></Typography>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default CarouselText;
