import axios from "axios";

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const cleanedBackendUrl = backendUrl.replace("/backend", "");


const BannerService = {
  getAllBanner() {
    return axios.get(`${cleanedBackendUrl}/banner`);
  },
  updateBanner(
    id,
    runningText,
    title,
    description,
    tagSecodnaryId,
    images,
    oldImages = []
  ) {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("tagSecondaryId", tagSecodnaryId)
    runningText.forEach(text => {
      formData.append("runningText[]", text);
    })
    images.forEach(image => {
      formData.append("images", image.originalFile);
    })
    oldImages.forEach(image => {
      formData.append("oldImages[]", image);
    })

    return axios.post(`${cleanedBackendUrl}/banner`, formData);
  }
}

export default BannerService;