import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Button, Grid } from '@mui/material';

const ItemTypes = {
  ITEM: 'item',
};

function ImageSortableItem({image, alt, index, moveItem, deleteItem}) {
  const ref = useRef(null);
  const imageUrl = "https://api.liquestore.com/assets/items/"

  const [, drop] = useDrop({
    accept: ItemTypes.ITEM,
    hover(item, monitor) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      // Check if the drag crosses the middle of the item (works in both horizontal and vertical flex layouts)
      const isVerticalMove = hoverClientY > hoverMiddleY;
      const isHorizontalMove = hoverClientX > hoverMiddleX;

      if (dragIndex < hoverIndex && (isVerticalMove || isHorizontalMove)) return;
      if (dragIndex > hoverIndex && (!isVerticalMove || !isHorizontalMove)) return;

      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.ITEM,
    item: { image, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref))

  return (
    <>
      <Grid item xs={3} sx={{ opacity: isDragging ? 0.5 : 1 }}> 
        <div ref={ref}>
          <div style={{ aspectRatio: "5 / 4" }}>
            <img src={`${imageUrl}${image}`} alt={`${alt} ${index + 1}`}  style={{ aspectRatio: "5 / 4", objectFit: "cover", width: "100%" }}/>
          </div>
          <Button sx={{ background: "#E50B0B", color: "#fff", display: "block", width: "100%" }} onClick={() => deleteItem(image)}>
            Hapus
          </Button>
        </div>
      </Grid>
    </>
  )
}

export default ImageSortableItem;