import axios from "axios";

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const cleanedBackendUrl = backendUrl.replace("/backend", "");

const TagService = {
  getTagList(flag = "") {
    return axios.get(`${cleanedBackendUrl}/tag`, {
      params: {
        flag: flag,
      },
    });
  },
  getShownTagList(flag = "") {
    return axios.get(`${cleanedBackendUrl}/tag/shown`, {
      params: {
        flag: flag,
      },
    });
  },
  insertTag(title, description, flag, shown) {
    return axios.post(`${cleanedBackendUrl}/tag`, {
      title: title,
      description: description,
      flag: flag,
      shown: shown
    });
  },
  updateTag(id, title, description, shown) {
    return axios.patch(`${cleanedBackendUrl}/tag/${id}`, {
      title: title,
      description: description,
      shown: shown
    });
  },
  deleteTag(id) {
    return axios.delete(`${cleanedBackendUrl}/tag/${id}`)
  }
};

export default TagService;
