import axios from "axios";

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const cleanedBackendUrl = backendUrl.replace("/backend", "");

const WishlistService = {
  getWishlistStatus(customerId, itemId) {
    return axios.get(`${cleanedBackendUrl}/wishlist/status`, {
      params: {
        customerId: customerId,
        itemId: itemId,
      },
    });
  },
  getWishlist(customerId) {
    return axios.get(`${cleanedBackendUrl}/wishlist`, {
      params: {
        customerId: customerId,
      },
    });
  },
  deleteWishlist(customerId, itemId) {
    return axios.delete(`${cleanedBackendUrl}/wishlist/item`, {
      data: {
        customerId: customerId,
        itemId: itemId,
      },
    });
  },
  deleteWishlistById(wishlistId) {
    return axios.delete(`${cleanedBackendUrl}/wishlist`, {
      data: {
        wishlistId: wishlistId,
      },
    });
  },
  addWishlist(customerId, itemId) {
    return axios.post(`${cleanedBackendUrl}/wishlist`, {
      customerId: customerId,
      itemId: itemId,
    });
  },
  addToCart(wishlistId) {
    return axios.post(`${cleanedBackendUrl}/wishlist/cart`, {
      wishlistId: wishlistId,
    });
  },
};

export default WishlistService;
