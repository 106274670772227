import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Collapsible from "react-collapsible";
import { QRCodeCanvas } from "qrcode.react";
import MoreIcon from "@mui/icons-material/MoreVert";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  Backdrop,
  Container,
  Fade,
  Grid,
  Modal,
  Button,
  TextField,
  Autocomplete,
  Alert,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import logoSmile from "../assets/smile.png";
import logoDana from "../assets/logoDana.png";
import logoShopeePay from "../assets/logoShopeePay.svg";
import logoOvo from "../assets/logoOvo.png";
import logoLinkAja from "../assets/logoLinkAja.png";
import logoJeniusPay from "../assets/logoJeniusPay.png";
import logoAstraPay from "../assets/logoAstraPay.png";
import logoBca from "../assets/bca.png";
import logoMandiri from "../assets/mandiri.png";
import logoBri from "../assets/bri.svg";
import logoBni from "../assets/bni.svg";
import logoCimb from "../assets/cimb.svg";
import logoPermata from "../assets/permata.svg";
import logoSampoerna from "../assets/sampoerna.svg";
import logoBjb from "../assets/bjb.svg";
import logoBsi from "../assets/bsi.svg";
import logoQris from "../assets/qris.svg";
import logoJnt from "../assets/logoJnt.png"
import noImage from "../../src/assets/no-image.png";

import axios from "axios";
import { useAuth } from "../authContext";
import LocalMallIcon from '@mui/icons-material/LocalMall';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import EditIcon from "@mui/icons-material/Edit";
import logoToko from "../assets/logo_copy.png";
import { NumericFormat } from "react-number-format";
import { el } from "date-fns/locale";
import Navigation from "./navigation";
import Footer from "./components/footer/footer";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(3),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(2),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "black",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "black",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const btnAddLocationStyle = {
  borderRadius: 5,
  padding: 10,
  boxShadow: 24,
  color: "black",
  fontWeight: "bold",
  backgroundColor: "#FE8A01",
  textTransform: "none",
};

const btnNewAddress = {
  borderRadius: 5,
  padding: 10,
  boxShadow: 24,
  backgroundColor: "white",
  color: "#FE8A01",
  fontWeight: "bold",
  border: "1px solid #FE8A01",
  textTransform: "none",
};

const btnPaymentStyle = {
  borderRadius: "10px",
  width: "20vw",
  backgroundColor: "#FE8A01",
  color: "black",
  border: "1px solid black",
  padding: 5,
  marginLeft: 25,
  textTransform: "none",
};

const btnBackStyle = {
  borderRadius: "10px",
  color: "white",
  width: "10vw",
  backgroundColor: "black",
  border: "1px solid white",
  padding: 5,
  textTransform: "none",
};

const btnMethodStyle = {
  borderRadius: "10px",
  fontSize: "1em",
  color: "white",
  width: "15vw",
  backgroundColor: "black",
  border: "1px solid white",
  padding: "5px 10px 5px 5px",
  textTransform: "none",
  display: "flex",
  justifyContent: "end"
};

const btnMethodSelectedStyle = {
  borderRadius: "10px",
  fontSize: "1em",
  color: "black",
  width: "15vw",
  backgroundColor: "white",
  // border: "1px solid white",
  padding: "5px 10px 5px 5px",
  textTransform: "none",
  display: "flex",
  justifyContent: "end"
};

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxHeight: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  textAlign: "center",
  overflowY: "auto",
  overflowX: "hidden",
};

const styleModalLogout = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  textAlign: "center",
  overflowY: "auto",
};

const styleModalAddLocation = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxHeight: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
  overflowY: "auto",
};

export default function CheckoutPage() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const cleanedBackendUrl = backendUrl.replace("/backend", "");
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [DataCust, setDataCust] = useState(null);
  const [DataOrder, setDataOrder] = useState([]);
  const [DataOrderbyPhone, setDataOrderbyPhone] = useState([]);
  const [DataPaymentbyPhone, setDataPaymentbyPhone] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [AdminFee, setAdminFee] = useState();
  const [isLoadingOrder, setIsLoadingOrder] = useState(true);
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const [AddressData, setAddressData] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [zipcodes, setZipcodes] = useState([]);
  const [subDistricts, setSubDistricts] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedSubDistrict, setSelectedSubDistrict] = useState(null);
  const [selectedNeighborhood, setselectedNeighborhood] = useState(null);
  const [selectedZipcode, setSelectedZipcode] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [selectedDelivery, setSelectedDelivery] = useState(null);
  const [selectedDeliveryType, setSelectedDeliveryType] = useState(null);
  const [selectedExpedition, setSelectedExpedition] = useState("JNT");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [openInformation, setOpenInformation] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState("");
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [paymentData, setPaymentData] = useState();
  const [cost, setCost] = useState(null);
  const [weight, setWeight] = useState(0); // example weight in grams
  const [originCityId, setOriginCityId] = useState(152); // example: Jakarta
  const [IdAddress, setIdAddress] = useState("");
  const [addressName, setaddressName] = useState("");
  const [address, setaddress] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [country, setcountry] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [note, setnote] = useState("");
  const [errors, setErrors] = useState("");
  const [openAddress, setOpenAddress] = useState(false);
  const [OpenAddLocation, setopenAddLocation] = useState(false);
  const [OpenAddpayment, setopenAddPayment] = useState(false);
  const [OpenChooseDelivery, setopenChooseDelivery] = useState(false);
  const handleCloseAddress = () => setOpenAddress(false);
  const handleCloseInformation = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setOpenInformation(false);
    }
  }
  const handleCloseAddLocation = () => setopenAddLocation(false);
  const handleCloseAddPayment = () => setopenAddPayment(false);
  const handleCloseChooseDelivery = () => setopenChooseDelivery(false);
  const [selectedShippingCost, setSelectedShippingCost] = useState(null);
  const [selectedShippingIndex, setSelectedShippingIndex] = useState(-1);
  const [selectedShippingCostValue, setSelectedShippingCostValue] = useState(0);
  const [productDeliveryPrice, setProductDeliveryPrice] = useState(0);
  const [ShowSuccess, setShowSuccess] = useState(false);
  const [MsgSuccess, setMsgSuccess] = useState("");
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState();
  const [openLogout, setOpenLogout] = useState(false);
  const handleOpenLogout = () => setOpenLogout(true);
  const handleCloseLogout = () => setOpenLogout(false);
  const { auth, logout } = useAuth();
  const getUsername = auth.user ? auth.user.username : "";
  const getId = auth.user ? auth.user.id : "";
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const storedOrderId = localStorage.getItem("orderid");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [pollingInterval, setPollingInterval] = useState(null);
  const [loading, setLoading] = useState(false);
  const [addressIsLoading, setAddressIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isLoadingPaymentMethod, setIsLoadingPaymentMethod] = useState(false);
  const [isLoadingDelivery, setIsLoadingDelivery] = useState(false);
  const { id } = useParams();
  console.log('CheckoutPage ID:', id);

  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const imageUrl = "https://api.liquestore.com/assets/items/"

  const handleCloseDelete = () => {
    setDeleteId(null);
    setOpenDelete(false);
  };

  const handleOpenDelete = (orderId) => {
    setDeleteId(orderId);
    setOpenDelete(true);
  };

  const deleteItem = async () => {
    setLoadingDelete(true)
    try {
      const response = await axios.put(
        `${cleanedBackendUrl}/orders/${deleteId}/status`,
        {
          id: deleteId,
          status: "CANCELED"
        }
      )

      if (response) {
        await fetchDataOrderbyPhone()
        handleAdminFee()
      }
    } catch (error) {
      console.error(error);
      setMsgError("Batal pesanan gagal")
    } finally {
      setLoadingDelete(false)
      handleCloseDelete()
    }
  }

  const handleNavigateHistory = () => {
    navigate('/customer/historyPage');
  };
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  const validate = () => {
    let tempErrors = {};

    // Validasi Nama
    if (!addressName) {
      tempErrors.addressName = "Nama address harus diisi";
    } else if (addressName.length > 25) {
      tempErrors.addressName = "Nama address maksimal 25 karakter";
    }

    // Validasi Varian
    if (!address) {
      tempErrors.address = "address harus diisi";
    } else if (address.length > 255) {
      tempErrors.address = "address maksimal 255 karakter";
    }

    // Validasi Weight
    if (!selectedCity) {
      tempErrors.city = "Kota harus diisi";
    }

    // Validasi Capital Price
    if (!selectedProvince) {
      tempErrors.state = "Provinsi harus diisi";
    }

    if (!zipcode) {
      tempErrors.zipcode = "zipcode harus diisi";
    } else if (zipcode.length > 6) {
      // contoh panjang maksimal 6 karakter
      tempErrors.zipcode = "zipcode maksimal 6 karakter";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const fetchProvinces = () => {
    setCities("");
    axios
      .get(`${cleanedBackendUrl}/address/provinces`)
      .then((response) => {
        setProvinces(response.data.provinceList);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  };

  const fetchCities = (provinceId) => {
    console.log(provinceId);
    axios
      .get(`${cleanedBackendUrl}/address/districts?province=${provinceId}`)
      .then((response) => {
        setCities(response.data.districtList);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the cities!", error);
      });
  };

  const fetchSubDistricts = (provinceId, cityId) => {
    console.log(provinceId, cityId);
    axios
      .get(
        `${cleanedBackendUrl}/address/subdistricts?province=${provinceId}&district=${cityId}`
      )
      .then((response) => {
        setSubDistricts(response.data.subdistrictList);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the sub districts!", error);
      });
  };

  const fetchneighborhoods = (provinceId, cityId, subDistrictId) => {
    console.log(provinceId, cityId, subDistrictId);
    axios
      .get(
        `${cleanedBackendUrl}/address/neighborhoods?province=${provinceId}&district=${cityId}&subdistrict=${subDistrictId}`
      )
      .then((response) => {
        setNeighborhoods(response.data.neighborhoodList);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the neighborhoods!", error);
      });
  };

  const fetchZipcode = (provinceId, cityId, subDistrictId) => {
    axios
      .get(
        `${cleanedBackendUrl}/address/zip-codes?province=${provinceId}&district=${cityId}&subdistrict=${subDistrictId}`
      )
      .then((response) => {
        setZipcodes(response.data.zipCodeList);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the neighborhoods!", error);
      });
  };

  const calculateShippingCost = () => {
    axios
      .get(
        `${backendUrl}/customer/api/rajaongkir/cost?originType=city&origin=${originCityId}&destinationType=city&destination=${selectedCity}&weight=${weight}`
      )
      .then((response) => {
        setCost(response.data.rajaongkir.results);
      })
      .catch((error) => {
        console.error(
          "There was an error calculating the shipping cost!",
          error
        );
      });
  };

  useEffect(() => {
    // Load Snap.js script
    const script = document.createElement("script");
    script.src = "https://app.sandbox.midtrans.com/snap/snap.js";
    script.setAttribute("data-client-key", "YOUR_CLIENT_KEY"); // Replace with your client key
    script.async = true;
    document.body.appendChild(script);
    fetchDataCust();

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const fetchDataCust = async () => {
    console.log(auth);
    setDataCust(auth.user);
    // if (getId !== "") {
    //   try {
    //     const response = await axios.get(
    //       `${backendUrl}/customer/getCustData?id=${getId}`
    //     );
    //     console.log(response.data);
    //     setDataCust(auth.user);
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //   }
    // }
  };

  const fetchDataOrder = async () => {
    if (getId !== "") {
      try {
        const response = await axios.get(
          `${backendUrl}/customer/getOrderData?id=${storedOrderId}`
        );
        console.log(response.data);
        setDataOrder(response.data);
        setWeight(response.data.totalWeight);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const fetchDataAddress = async () => {
    try {
      const response = await axios.get(
        `${cleanedBackendUrl}/customer/${DataCust.phonenumber}/address`
      );
      console.log("Address List:", response.data);
      setAddressData(response.data.addressList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataOrderbyId = async () => {
    setIsLoadingOrder(true); // Set loading state to true
    try {
      const response = await axios.get(
        `${cleanedBackendUrl}/payments/${id}`
      );
      console.log(response);
      setDataOrderbyPhone(response.data.orderList || []); // Safely set the order list
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataOrderbyPhone([]); // Set to an empty array on error to avoid breaking the UI
    } finally {
      setIsLoadingOrder(false); // Set loading state to false
    }
  };

  const fetchDataOrderbyPhone = async () => {
    setIsLoadingOrder(true); // Set loading state to true
    try {
      const response = await axios.get(
        `${cleanedBackendUrl}/payments/preview?phone-number=${DataCust.phonenumber}`
      );
      console.log(response);
      setDataOrderbyPhone(response.data.orderList || []); // Safely set the order list
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataOrderbyPhone([]); // Set to an empty array on error to avoid breaking the UI
    } finally {
      setIsLoadingOrder(false); // Set loading state to false
    }
  };

  const fetchDataPaymentbyPhone = async () => {
    setIsLoadingPayment(true); // Set loading state to true

    try {
      const response = await axios.get(
        `${cleanedBackendUrl}/payments?phone-number=${DataCust.phonenumber}`
      );
      console.log(response);
      setDataPaymentbyPhone(response.data.paymentList || []); // Safely set the payment list
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataPaymentbyPhone([]); // Set to an empty array on error to avoid breaking the UI
    } finally {
      setIsLoadingPayment(false); // Set loading state to false
    }
  };

  useEffect(() => {
    console.log("Data yang di filter:", DataOrderbyPhone);
    const filtered = DataOrderbyPhone.filter((order) => {
      return (
        order.status.key === "DRAFT" ||
        order.status.key === "WAITING_FOR_PAYMENT"
      );
    });

    // Set filteredOrders state
    setFilteredOrders(filtered);

    console.log("Filtered orders BY PHONE (after update):", filtered);

    // Calculate the total price based on filtered orders
    const total = filtered.reduce((sum, order) => sum + order.price, 0);

    // Set the total price state
    setTotalPrice(total);

    console.log("Total price based on filtered orders:", total);
  }, [DataOrderbyPhone]);

  useEffect(() => {
    console.log("Filtered orders (after update):", filteredOrders);
  }, [filteredOrders]);

  useEffect(() => {
    console.log("payments BY PHONE (after update):", DataPaymentbyPhone);
    // console.log("price (after update):", DataPaymentbyPhone[0].price);
  }, [DataPaymentbyPhone]);

  // useEffect(() => {
  //   if (
  //     AddressData &&
  //     AddressData.length > 0 &&
  //     AddressData[0].customer.phonenumber
  //   ) {
  //     fetchDataPaymentbyPhone();
  //   }
  // }, [AddressData]);

  useEffect(() => {
    if (selectedProvince) {
      console.log(selectedProvince);
      fetchCities(selectedProvince);
    }
  }, [selectedProvince]);

  useEffect(() => {
    if (selectedCity) {
      console.log(selectedCity);
      fetchSubDistricts(selectedProvince, selectedCity);
    }
  }, [selectedCity]);

  useEffect(() => {
    if (selectedSubDistrict) {
      console.log(selectedSubDistrict);
      fetchneighborhoods(selectedProvince, selectedCity, selectedSubDistrict);
      fetchZipcode(selectedProvince, selectedCity, selectedSubDistrict);
    }
  }, [selectedSubDistrict]);

  useEffect(() => {
    if (selectedAddress) {
      const selected = AddressData.find(
        (address) => address.id === parseInt(selectedAddress)
      );
      if (selected) {
        setSelectedData(selected); // Move the selected address data into selectedData
      }
    }
  }, [selectedAddress, AddressData]);

  useEffect(() => {
    console.log(selectedData);
  }, [selectedData]);

  useEffect(() => {
    // fetchDataCust();
    // fetchDataOrder();
    // fetchDataAddress();
  }, []);

  useEffect(() => {
    if (DataCust) {
      console.log("Data Customer:", DataCust);
      // fetchDataOrderbyId();
      fetchDataOrderbyPhone();
      fetchDataAddress();
    }
  }, [DataCust]);

  useEffect(() => {
    return () => {
      if (pollingInterval) clearInterval(pollingInterval);
    };
  }, [pollingInterval]);

  const startPolling = (referenceId) => {
    const intervalId = setInterval(async () => {
      try {
        const response = await axios.get(`${cleanedBackendUrl}/payments/${referenceId}`);
        console.log(response)
        const status = response?.data?.status;

        // Check if payment is successful
        if (status === "SUCCEEDED") {
          clearInterval(intervalId); // Stop polling
          setPollingInterval(null); // Reset polling state

          // Show success modal
          setOpenSuccessModal(true);

          // Hide modal and redirect after 5 seconds
          setTimeout(() => {
            setOpenSuccessModal(false);
            navigate("/customer/historyPage");
          }, 5000);
        }
      } catch (error) {
        console.error("Error checking payment status:", error);
      }
    }, 3000); // Poll every 3 seconds

    setPollingInterval(intervalId); // Save interval ID to state
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleOpenAddress = () => {
    setOpenAddress(true);
  };

  const handleOpenAddLocation = () => {
    fetchProvinces();
    setopenAddLocation(true);
    setOpenAddress(false);
  };

  const handleOpenAddPayment = () => {
    setopenAddPayment(true);
  };

  const handleOpenChooseDelivery = () => {
    setopenChooseDelivery(true)
  }

  const handleSelectPayment = (paymentMethod, paymentMethodName) => {
    setSelectedPayment(paymentMethod);
    setPaymentMethod(paymentMethodName);
  };

  const handleSelectDeliveryMethod = (expedition, type) => {
    setSelectedDelivery(`${expedition}_${type}`)
    setSelectedExpedition(expedition)
    setSelectedDeliveryType(type)
  }

  const handleOpenEdit = (row) => {
    fetchProvinces();
    // setIdAddress(row.id);
    setaddress(row.detail);
    // setaddressName(row.addressname);
    setSelectedCity(row.district);
    setSelectedProvince(row.province);
    setSelectedSubDistrict(row.subdistrict);
    setselectedNeighborhood(row.neighborhood);
    // setcountry(row.country);
    setzipcode(row.zipCode);
    // setnote(row.note);
    setopenAddLocation(true);
  };

  const handleRadioChange = (event) => {
    setSelectedAddress(event.target.value); // Keeps the selected address
  };

  const handleSaveAddress = async () => {
    setAddressIsLoading(true);
    console.log(selectedAddress, selectedPayment, DataCust.phonenumber);
    try {
      const response = await axios.get(
        `${cleanedBackendUrl}/payments/preview`,
        {
          params: {
            "phone-number": DataCust.phonenumber, // Updated key
            method: "EWALLET",
            channel: selectedPayment,
            "address-id": selectedAddress, // Updated key
          },
        }
      );
      // Handle the response data if needed
      console.log(response.data);
      setPaymentData(response.data);
    } catch (error) {
      // Handle any errors
      console.error("Error fetching payment data:", error);
    }
    setAddressIsLoading(false);
    setOpenAddress(false);
  };

  useEffect(() => {
    if (originCityId && selectedCity && weight) {
      calculateShippingCost();
    } else {
      console.error(
        "One or more parameters (originCityId, selectedCity, weight) are missing or invalid."
      );
    }
  }, [originCityId, selectedCity, weight]);

  const handleSubmitAddress = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const formData = new FormData();
        formData.append("id", IdAddress ? parseInt(IdAddress, 10) : "");
        formData.append("addressname", addressName);
        formData.append("addressdetail", address);
        formData.append("cityId", selectedCity ? selectedCity.city_id : "");
        formData.append("city", selectedCity ? selectedCity.city_name : "");
        formData.append(
          "state",
          selectedProvince ? selectedProvince.province : ""
        );
        formData.append("country", country);
        formData.append("zipcode", zipcode);
        formData.append("note", note);
        formData.append("customerid", getId);

        console.log([...formData]);
        const response = await axios.post(
          `${backendUrl}/customer/tambahAddress`,
          formData
        );
        console.log(response.data);
        setopenAddLocation(false);
        setOpenAddress(false);
        setShowSuccess(true);
        setMsgSuccess(response.data);
        setTimeout(() => {
          setShowSuccess(false);
        }, 5000);
        fetchDataAddress();
        setaddressName("");
        setaddress("");
        setcity("");
        setstate("");
        setcountry("");
        setnote("");
      } catch (error) {
        setErrors(error.response);
        setShowError(true);
        setMsgError("Gagal Menambah Address");
        setTimeout(() => {
          setShowError(false);
        }, 5000);
      }
    } else {
      console.log("Validation failed");
    }
  };

  const [loadingSubmitAddress, setLoadingSubmitAddress] = useState(false);

  const handleSubmitAddress2 = async (e) => {
    setLoadingSubmitAddress(true);
    const addressData = {
      province: selectedProvince,
      district: selectedCity,
      subdistrict: selectedSubDistrict,
      neighborhood: selectedNeighborhood,
      zipCode: selectedZipcode,
      detail: address,
    };

    console.log(addressData);

    try {
      const response = await axios.post(
        `${cleanedBackendUrl}/customer/${DataCust.phonenumber}/address`,
        addressData
      );
      console.log(response.data);
      setShowSuccess(true);
      setMsgSuccess("Address added successfully!");
      setTimeout(() => {
        setShowSuccess(false);
      }, 5000);
    } catch (error) {
      setErrors(error.response);
      setShowError(true);
      setMsgError("Failed to add address");
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    }
    handleCloseAddLocation();
    fetchDataAddress();
    setLoadingSubmitAddress(true);
  };

  // const handleSelectShippingCost = (selectedCost, index) => {
  //   setSelectedShippingCost(selectedCost);
  //   setSelectedShippingIndex(index);
  // };

  useEffect(() => {
    if (cost && cost.length > 0 && cost[0].costs.length > 0) {
      setSelectedShippingCost(cost[0].costs[0]);
      setSelectedShippingIndex(0);
    }
    // Contoh: menghitung total price berdasarkan DataOrder dan selectedShippingCost
    const calculateTotalPrice = () => {
      let totalPrice = parseInt(DataOrder.totalPrice) + 2000;
      if (selectedShippingCost) {
        totalPrice += parseInt(selectedShippingCost.cost[0].value);
        setSelectedShippingCostValue(selectedShippingCost.cost[0].value);
      }

      setProductDeliveryPrice(totalPrice);
    };

    calculateTotalPrice();
  }, [cost, DataOrder, selectedShippingCost]);

  // console.log(productDeliveryPrice);

  const handlePayment = async () => {
    setLoading(true);
    console.log(getId);

    // const response = await axios.get(
    //   `${cleanedBackendUrl}/orders/preview?phone-number=${paymentData.phoneNumber}`
    // );
    const orderIdList = paymentData.orderList;
    console.log(orderIdList);

    const postData = {
      phoneNumber: paymentData.phoneNumber,
      method: paymentMethod === "COD" ? "CASH_ON_DELIVERY" : paymentData.method,
      channel: paymentData.channel,
      orderIdList: orderIdList.map((order) => order.id), // Extract order IDs
      addressId: selectedAddress,
    };
    console.log(postData);

    try {
      const response2 = await axios.post(
        `${cleanedBackendUrl}/payments`,
        postData
      );

      if (!response2.data.paymentLink) {
        let paymentInformation = null;
        if (paymentMethod === "VIRTUAL_ACCOUNT") {
          paymentInformation = {
            virtualAccountNumber: response2?.data?.virtualAccountNumber,
            referenceId: response2?.data?.referenceId,
          };
        } else if (paymentMethod === "EWALLET") {
          paymentInformation = {
            message: "Silakan lanjutkan pembayaran di aplikasi E-Wallet anda",
            referenceId: response2?.data?.referenceId,
          };
        } else if (paymentMethod === "QR_CODE") {
          paymentInformation = {
            qrCode: response2?.data?.qrCode,
            referenceId: response2?.data?.referenceId,
            totalPrice: response2?.data?.totalPrice,
          };
        } else if (paymentMethod === "COD") {
          paymentInformation = {
            referenceId: response2?.data?.referenceId,
            totalPrice: response2?.data?.totalPrice,
            message: "Mohon tunggu sejenak"
          }
        }

        if (paymentInformation !== null) {
          startPolling(response2?.data?.referenceId);
        }

        setPaymentInfo(paymentInformation);
        setOpenInformation(true);
        // window.alert("Silakan lanjutkan pembayaran di aplikasi E-Wallet anda");
      } else {
        console.log(response2.data.paymentLink);
        window.open(response2.data.paymentLink, "_blank");
      }
    } catch (error) {
      console.error("Error posting data:", error);
      setErrors(error?.response?.data?.message);
      setMsgError(error?.response?.data?.message);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    } finally {
      setLoading(false);
    }
    // if (getId === ''){
    //   setShowError(true);
    //   setMsgError("login terlebih dahulu");
    //   setTimeout(() => {
    //     setShowError(false);
    //   }, 5000);
    // }
    // else if (selectedData == null) {
    //   setShowError(true);
    //   setMsgError("Pilih alamat terlebih dahulu");
    //   setTimeout(() => {
    //     setShowError(false);
    //   }, 5000);
    // }
    // else{
    //   const formData = new FormData();
    //   formData.append('masterorderid', DataOrder.listTempOrder[0].masterorderid);
    //   formData.append('totalprice', productDeliveryPrice);
    //   formData.append('customerid', getId);
    //   formData.append('address', selectedData.addressdetail);
    //   formData.append('city', selectedData.city);
    //   formData.append('zipcode', selectedData.zipcode);
    //   formData.append('weight', weight);
    //   formData.append('deliveryprice', selectedShippingCostValue);

    //   console.log([...formData])
    //   try {
    //     const response = await axios.post(`${backendUrl}/customer/api/payment`, formData);
    //     const token = response.data.token;
    //     console.log(token);

    //     if (window.snap && window.snap.pay) {
    //       window.snap.pay(token, {
    //         onSuccess: function(result) {
    //           console.log("Payment success!", result);
    //         },
    //         onPending: function(result) {
    //           console.log("Waiting for payment!", result);
    //         },
    //         onError: function(result) {
    //           console.log("Payment failed!", result);
    //         },
    //         onClose: function() {
    //           console.log("You closed the popup without finishing the payment");
    //           // Additional precaution to prevent any navigation:
    //           window.history.pushState(null, '', window.location.href);
    //         },
    //       });
    //     } else {
    //       console.error('Snap.js failed to load or is not properly initialized');
    //     }
    //   } catch (error) {
    //     console.error('Payment error: ', error);
    //   }
    // }
  };

  const handleBack = async (e) => {
    e.preventDefault();
    setSelectedPayment(null);
    setSelectedAddress(null);
  };

  useEffect(() => {
    console.log(paymentData);
  }, [paymentData]);

  const handleAdminFee = async () => {
    setIsLoadingPaymentMethod(true);
    console.log(selectedAddress, selectedPayment, DataCust.phonenumber);
    if (selectedPayment) {
      try {
        const response = await axios.get(
          `${cleanedBackendUrl}/payments/preview`,
          {
            params: {
              "phone-number": DataCust.phonenumber, // Updated key
              method: paymentMethod === 'COD' ? null : paymentMethod,
              channel: selectedPayment === 'COD' ? null : selectedPayment,
              "address-id": selectedAddress, // Updated key
            },
          }
        );
        // Handle the response data if needed
        setPaymentData(response.data);
      } catch (error) {
        // Handle any errors
        console.error("Error fetching admin fee:", error);
        setErrors(error?.response?.data?.message);
        setMsgError(error?.response?.data?.message);
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 5000);
      }
      finally {
        setIsLoadingPaymentMethod(false);
      }
    }
    setopenAddPayment(false);
  };

  const handleCheckDelivery = async () => {
    setIsLoadingDelivery(true)
    if (selectedDelivery) {
      try {

      } catch (error) {
        // Handle any errors
        // console.error("Error fetching admin fee:", error);
        setErrors(error?.response?.data?.message);
        setMsgError(error?.response?.data?.message);
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 5000);
      } finally {
        setIsLoadingDelivery(false)
      }
    }
    setopenChooseDelivery(false)
  }

  const handleLogout = () => {
    localStorage.removeItem("orderid");
    setOpenLogout(false);
    logout();
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      style={{ backgroundColor: "#FE8A01" }}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const eWalletTrigger = (
    <Box
      sx={{
        marginTop: "24px",
        minHeight: "96px",
        height: "auto",
        width: "100%",
        borderRadius: "20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "2px solid black",
        "&:hover": {
          bgcolor: "rgba(0, 0, 0, 0.23)",
        },
      }}
    >
      <Grid
        container
        sx={{
          textAlign: "left",
          padding: "20px",
        }}
      >
        <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
          <ExpandMoreIcon />
          E-Wallet
        </Grid>
        <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={logoDana}
            alt="Dana Logo"
            width={60}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
          <img
            src={logoOvo}
            alt="OVO Logo"
            width={60}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
          <img
            src={logoShopeePay}
            alt="Shopee Pay Logo"
            width={60}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
          <img
            src={logoLinkAja}
            alt="Link Aja Logo"
            width={60}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
          <img
            src={logoAstraPay}
            alt="Astra Pay Logo"
            width={60}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
          <img
            src={logoJeniusPay}
            alt="Jenius Pay Logo"
            width={60}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
        </Grid>
      </Grid>
    </Box>
  );

  const eWalletCloseTrigger = (
    <Box
      sx={{
        marginTop: "24px",
        minHeight: "96px",
        height: "auto",
        width: "100%",
        borderRadius: "20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "2px solid black",
        "&:hover": {
          bgcolor: "rgba(0, 0, 0, 0.23)",
        },
      }}
    >
      <Grid
        container
        sx={{
          textAlign: "left",
          padding: "20px",
        }}
      >
        <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
          <ChevronRightIcon />
          E-Wallet
        </Grid>
        <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={logoDana}
            alt="Dana Logo"
            width={60}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
          <img
            src={logoOvo}
            alt="OVO Logo"
            width={60}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
          <img
            src={logoShopeePay}
            alt="Shopee Pay Logo"
            width={60}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
          <img
            src={logoLinkAja}
            alt="Link Aja Logo"
            width={60}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
          <img
            src={logoAstraPay}
            alt="Astra Pay Logo"
            width={60}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
          <img
            src={logoJeniusPay}
            alt="Jenius Pay Logo"
            width={60}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
        </Grid>
      </Grid>
    </Box>
  );

  const bankTransferTrigger = (
    <Box
      sx={{
        marginTop: "24px",
        minHeight: "96px",
        height: "auto",
        width: "100%",
        borderRadius: "20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "2px solid black",
        "&:hover": {
          bgcolor: "rgba(0, 0, 0, 0.23)",
        },
      }}
    >
      <Grid
        container
        sx={{
          textAlign: "left",
          padding: "20px",
        }}
      >
        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
          <ExpandMoreIcon />
          Virtual Account
        </Grid>
        <Grid item xs={9} sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={logoBca}
            alt="BCA Logo"
            width={45}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
          <img
            src={logoMandiri}
            alt="Mandiri Logo"
            width={45}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
          <img
            src={logoBri}
            alt="BRI Logo"
            width={45}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
          <img
            src={logoBni}
            alt="BNI Logo"
            width={45}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
          <img
            src={logoCimb}
            alt="CIMB Logo"
            width={45}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
          <img
            src={logoPermata}
            alt="Permata Logo"
            width={45}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
          <img
            src={logoSampoerna}
            alt="Sampoerna Logo"
            width={45}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
          <img
            src={logoBjb}
            alt="BJB Logo"
            width={45}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
          <img
            src={logoBsi}
            alt="BSI Logo"
            width={45}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
        </Grid>
      </Grid>
    </Box>
  );

  const bankTransferCloseTrigger = (
    <Box
      sx={{
        marginTop: "24px",
        minHeight: "96px",
        height: "auto",
        width: "100%",
        borderRadius: "20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "2px solid black",
        "&:hover": {
          bgcolor: "rgba(0, 0, 0, 0.23)",
        },
      }}
    >
      <Grid
        container
        sx={{
          textAlign: "left",
          padding: "20px",
        }}
      >
        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
          <ChevronRightIcon />
          Virtual Account
        </Grid>
        <Grid item xs={9} sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={logoBca}
            alt="BCA Logo"
            width={45}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
          <img
            src={logoMandiri}
            alt="Mandiri Logo"
            width={45}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
          <img
            src={logoBri}
            alt="BRI Logo"
            width={45}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
          <img
            src={logoBni}
            alt="BNI Logo"
            width={45}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
          <img
            src={logoCimb}
            alt="CIMB Logo"
            width={45}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
          <img
            src={logoPermata}
            alt="Permata Logo"
            width={45}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
          <img
            src={logoSampoerna}
            alt="Sampoerna Logo"
            width={45}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
          <img
            src={logoBjb}
            alt="BJB Logo"
            width={45}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
          <img
            src={logoBsi}
            alt="BSI Logo"
            width={45}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
        </Grid>
      </Grid>
    </Box>
  );

  const qrisTrigger = (
    <Box
      sx={{
        marginTop: "24px",
        minHeight: "96px",
        height: "auto",
        width: "100%",
        borderRadius: "20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "2px solid black",
        "&:hover": {
          bgcolor: "rgba(0, 0, 0, 0.23)",
        },
      }}
    >
      <Grid
        container
        sx={{
          textAlign: "left",
          padding: "20px",
        }}
      >
        <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
          <ExpandMoreIcon />
          QR Code
        </Grid>
        <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={logoQris}
            alt="QRIS Logo"
            width={90}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
        </Grid>
      </Grid>
    </Box>
  );

  const qrisCloseTrigger = (
    <Box
      sx={{
        marginTop: "24px",
        minHeight: "96px",
        height: "auto",
        width: "100%",
        borderRadius: "20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "2px solid black",
        "&:hover": {
          bgcolor: "rgba(0, 0, 0, 0.23)",
        },
      }}
    >
      <Grid
        container
        sx={{
          textAlign: "left",
          padding: "20px",
        }}
      >
        <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
          <ChevronRightIcon />
          QR Code
        </Grid>
        <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={logoQris}
            alt="QRIS Logo"
            width={90}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
        </Grid>
      </Grid>
    </Box>
  );

  const jntTrigger = (
    <Box
      sx={{
        marginTop: "24px",
        minHeight: "96px",
        height: "auto",
        width: "100%",
        borderRadius: "20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "2px solid black",
        "&:hover": {
          bgcolor: "rgba(0, 0, 0, 0.23)",
        },
      }}
    >
      <Grid
        container
        sx={{
          textAlign: "left",
          padding: "20px",
        }}
      >
        <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
          <ExpandMoreIcon />
          JNT
        </Grid>
        <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={logoJnt}
            alt="JNT Logo"
            width={90}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
        </Grid>
      </Grid>
    </Box>
  );

  const jntCloseTrigger = (
    <Box
      sx={{
        marginTop: "24px",
        minHeight: "96px",
        height: "auto",
        width: "100%",
        borderRadius: "20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "2px solid black",
        "&:hover": {
          bgcolor: "rgba(0, 0, 0, 0.23)",
        },
      }}
    >
      <Grid
        container
        sx={{
          textAlign: "left",
          padding: "20px",
        }}
      >
        <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
          <ChevronRightIcon />
          JNT
        </Grid>
        <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={logoJnt}
            alt="JNT Logo"
            width={90}
            style={{
              transition: "background-color 0.3s",
              paddingRight: "10px",
            }} // Add smooth transition
          />
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <>
      <Navigation />

      <Container component="main" sx={{ marginBottom: "50px" }}>
        {ShowSuccess && (
          <Alert variant="filled" severity="success" style={{ marginTop: 20 }}>
            {MsgSuccess}
          </Alert>
        )}
        {showError && (
          <Alert variant="filled" severity="error" style={{ marginTop: 20 }}>
            {msgError}
          </Alert>
        )}
        <Box
          sx={{
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
            borderRadius: 3,
            boxShadow: "10px 10px 5px gray",
            backgroundColor: "black",
            height: "auto",
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={12} sx={{ position: "relative" }}>
              <Box
                sx={{ backgroundColor: "#FE8A01", height: "auto", padding: 5 }}
              >
                <Typography variant="h3" fontWeight={"bolder"}>
                  Checkout
                </Typography>
              </Box>
              <img
                src={logoToko}
                alt="Lique Store"
                style={{ width: 500, position: "absolute", top: 60, zIndex: 1 }}
              />
            </Grid>
            <Grid item xs={12} marginTop={10}>
              <Box sx={{ padding: 2 }}>
                <Box
                  sx={{
                    padding: 2,
                    margin: 4,
                    backgroundColor: "#FE8A01",
                    borderRadius: 3,
                    cursor: "pointer",
                  }}
                  onClick={handleOpenAddress}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <Typography>Deliver to</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "right" }}>
                      {selectedData && selectedData.detail ? (
                        <>
                          <Typography variant="h5">
                            {DataCust.username} - {DataCust.phonenumber}
                          </Typography>
                          <Typography>{selectedData.detail}</Typography>
                        </>
                      ) : (
                        <>
                          <Typography variant="h5">
                            You haven't added any address yet
                          </Typography>
                          <Typography>
                            Add your shipping address to continue
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Box>
                <Modal
                  aria-labelledby="spring-modal-title"
                  aria-describedby="spring-modal-description"
                  open={openInformation}
                  onClose={handleCloseInformation}
                  closeAfterTransition
                  slots={{ backdrop: Backdrop }}
                  slotProps={{
                    backdrop: {
                      TransitionComponent: Fade,
                    },
                  }}
                >
                  <Fade in={openInformation}>
                    <Box sx={styleModal}>
                      <Box
                        sx={{
                          width: "100%",
                          paddingTop: "200px",
                          paddingBottom: "200px",
                          paddingLeft: "50px",
                          paddingRight: "50px",
                        }}
                      >
                        {paymentMethod === "VIRTUAL_ACCOUNT" ? (
                          <>
                            {/* <Typography
                              variant="h5"
                              sx={{
                                textAlign: "left",
                              }}
                            >
                              Reference id: {paymentInfo?.referenceId}
                            </Typography> */}
                            <Typography
                              variant="h5"
                              sx={{
                                textAlign: "left",
                              }}
                            >
                              No. Virtual Account:{" "}
                              {paymentInfo?.virtualAccountNumber}
                            </Typography>
                            <Typography
                              variant="h5"
                              sx={{
                                textAlign: "left",
                              }}
                            >
                              Total yang harus dibayarkan:
                              <NumericFormat
                                value={paymentData?.totalPrice}
                                displayType={"text"}
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix={" Rp. "}
                              />
                            </Typography>
                          </>
                        ) : (
                          <>
                            {/* <Typography
                              variant="h5"
                              sx={{
                                textAlign: "left",
                              }}
                            >
                              Reference id: {paymentInfo?.referenceId}
                            </Typography> */}
                            <Typography
                              variant="h5"
                              sx={{
                                textAlign: "left",
                              }}
                            >
                              {paymentInfo?.message}
                            </Typography>
                            <Typography
                              variant="h5"
                              sx={{
                                textAlign: "left",
                              }}
                            >
                              Total yang harus dibayarkan:
                              <NumericFormat
                                value={paymentData?.totalPrice}
                                displayType={"text"}
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix={" Rp. "}
                              />
                            </Typography>

                            {/* QR Code Section */}
                            {paymentInfo?.qrCode ? (
                              <Box
                                sx={{
                                  marginTop: "20px",
                                  textAlign: "center",
                                  paddingRight: "125px",
                                }}
                              >
                                <QRCodeCanvas
                                  value={paymentInfo.qrCode}
                                  size={256}
                                />
                                <Typography
                                  variant="body1"
                                  sx={{ marginTop: "10px" }}
                                >
                                  Scan this QR code to complete your payment
                                </Typography>
                              </Box>
                            ) : (
                              // <Typography variant="body1" color="error">
                              //   QR Code not available
                              // </Typography>
                              <div></div>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                  </Fade>
                </Modal>

                {/* Success pop-up Modal */}
                <Modal
                  open={openSuccessModal}
                  // onClose={() => setOpenSuccessModal(false)}
                  disableEscapeKeyDown
                  aria-labelledby="success-modal-title"
                  aria-describedby="success-modal-description"
                  BackdropProps={{ style: { pointerEvents: "none" } }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      bgcolor: "background.paper",
                      border: "2px solid #000",
                      boxShadow: 24,
                      p: 4,
                      textAlign: "center",
                    }}
                  >
                    <Typography id="success-modal-title" variant="h6">
                      Payment Successful!
                    </Typography>
                    <Typography id="success-modal-description" sx={{ mt: 2 }}>
                      You will be redirected to the homepage shortly.
                    </Typography>
                  </Box>
                </Modal>

                <Modal
                  aria-labelledby="spring-modal-title"
                  aria-describedby="spring-modal-description"
                  open={openAddress}
                  onClose={handleCloseAddress}
                  closeAfterTransition
                  slots={{ backdrop: Backdrop }}
                  slotProps={{
                    backdrop: {
                      TransitionComponent: Fade,
                    },
                  }}
                >
                  <Fade in={openAddress}>
                    <Box sx={styleModal}>
                      {addressIsLoading ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <CircularProgress size={60} />{" "}
                          {/* Display loading spinner */}
                        </Box>
                      ) : AddressData.length > 0 ? (
                        <>
                          <RadioGroup
                            value={selectedAddress}
                            onChange={(event) => handleRadioChange(event)}
                          >
                            {AddressData.map((row, index) => (
                              <Box
                                key={index}
                                sx={{
                                  backgroundColor: "black",
                                  color: "white",
                                  borderRadius: 10,
                                  padding: 5,
                                  marginTop: 2,
                                  marginBottom: 2,
                                }}
                              >
                                <Grid container spacing={2} textAlign={"left"}>
                                  <Grid item xs={7}>
                                    <Typography
                                      component="h6"
                                      sx={{ fontWeight: "600" }}
                                    >
                                      {row.detail}
                                    </Typography>
                                  </Grid>
                                  <Grid container>
                                    <Grid
                                      item
                                      xs={7}
                                      sx={{ paddingLeft: "16px" }}
                                    >
                                      <Typography component="h6">
                                        {row.province}, {row.district},{" "}
                                        {row.subdistrict}, {row.neighborhood},{" "}
                                        {row.zipCode}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={5}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                      }}
                                    >
                                      <IconButton
                                        onClick={() => handleOpenEdit(row)}
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <EditIcon sx={{ color: "white" }} />
                                      </IconButton>
                                      <Box>
                                        <FormControlLabel
                                          value={row.id.toString()}
                                          control={
                                            <Radio
                                              sx={{
                                                color: "white",
                                                marginLeft: "4px",
                                              }}
                                              onClick={handleSaveAddress}
                                              data-index={index} // Add index as a data attribute
                                            />
                                          }
                                          label=""
                                        />
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>
                            ))}
                          </RadioGroup>
                          <Box>
                            <Grid container spacing={2}>
                              {/* <Grid item xs={6}>
                                <Button
                                  variant="contained"
                                  onClick={handleSaveAddress}
                                  disabled={!selectedAddress}
                                  style={btnAddLocationStyle}
                                >
                                  Save Address
                                </Button>
                              </Grid> */}
                              <Grid item xs={12}>
                                <Button
                                  variant="contained"
                                  onClick={handleOpenAddLocation}
                                  style={btnNewAddress}
                                >
                                  New Address
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </>
                      ) : (
                        <Box>
                          <img src={logoSmile} alt="Smile Logo" width={100} />
                          <Typography
                            id="spring-modal-title"
                            variant="h6"
                            component="h3"
                          >
                            You haven't added any address yet.
                          </Typography>
                          <Box sx={{ mt: 2 }}>
                            <Button
                              variant="contained"
                              onClick={handleOpenAddLocation}
                              style={btnAddLocationStyle}
                            >
                              Add Your Location
                            </Button>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Fade>
                </Modal>
                <Modal
                  aria-labelledby="spring-modal-title"
                  aria-describedby="spring-modal-description"
                  open={OpenAddLocation}
                  onClose={handleCloseAddLocation}
                  closeAfterTransition
                  slots={{ backdrop: Backdrop }}
                  slotProps={{
                    backdrop: {
                      TransitionComponent: Fade,
                    },
                  }}
                >
                  <Fade in={OpenAddLocation}>
                    <Box sx={styleModalAddLocation}>
                      <form>
                        <Grid container spacing={3}>
                          {/* <Grid item xs={12}>
                            <Typography>Save Address As *</Typography>
                            <TextField
                              fullWidth
                              autoComplete="off"
                              value={addressName}
                              error={!!errors.addressName}
                              helperText={errors.addressName}
                              FormHelperTextProps={{ sx: { color: "red" } }}
                              onChange={(e) => setaddressName(e.target.value)}
                            />
                          </Grid> */}
                          <Grid item xs={12}>
                            <Typography>Address *</Typography>
                            <TextField
                              fullWidth
                              autoComplete="off"
                              value={address}
                              error={!!errors.address}
                              helperText={errors.address}
                              FormHelperTextProps={{ sx: { color: "red" } }}
                              onChange={(e) => setaddress(e.target.value)}
                              placeholder="Jl. Melati Mas..." // Add this line
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>State / Province *</Typography>
                            <Autocomplete
                              options={provinces} // provinces is the array of strings from your fetch
                              getOptionLabel={(option) => option} // Since option is a string, you can use it directly
                              value={selectedProvince}
                              onChange={(event, newValue) => {
                                console.log("Selected Province:", newValue);
                                setSelectedProvince(newValue);
                                // Additional actions after setting selectedProvince
                              }}
                              helperText={errors.selectedProvince}
                              FormHelperTextProps={{ sx: { color: "red" } }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  margin="normal"
                                  fullWidth
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>District *</Typography>
                            <Autocomplete
                              options={cities}
                              getOptionLabel={(option) => option}
                              value={selectedCity}
                              onChange={(event, newValue) => {
                                console.log("Selected City:", newValue);
                                setSelectedCity(newValue);
                              }}
                              helperText={errors.selectedCity}
                              FormHelperTextProps={{ sx: { color: "red" } }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  margin="normal"
                                  fullWidth
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>Sub District *</Typography>
                            <Autocomplete
                              options={subDistricts} // provinces is the array of strings from your fetch
                              getOptionLabel={(option) => option} // Since option is a string, you can use it directly
                              value={selectedSubDistrict}
                              onChange={(event, newValue) => {
                                console.log("Selected Sub District:", newValue);
                                setSelectedSubDistrict(newValue);
                                // Additional actions after setting selectedProvince
                              }}
                              helperText={errors.selectedProvince}
                              FormHelperTextProps={{ sx: { color: "red" } }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  margin="normal"
                                  fullWidth
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>Neigboorhood *</Typography>
                            <Autocomplete
                              options={neighborhoods}
                              getOptionLabel={(option) => option}
                              value={selectedNeighborhood}
                              onChange={(event, newValue) =>
                                setselectedNeighborhood(newValue)
                              }
                              helperText={errors.selectedCity}
                              FormHelperTextProps={{ sx: { color: "red" } }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  margin="normal"
                                  fullWidth
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>Zip Code *</Typography>
                            <Autocomplete
                              options={zipcodes}
                              getOptionLabel={(option) => option}
                              value={selectedZipcode}
                              onChange={(event, newValue) =>
                                setSelectedZipcode(newValue)
                              }
                              helperText={errors.selectedCity}
                              FormHelperTextProps={{ sx: { color: "red" } }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  margin="normal"
                                  fullWidth
                                />
                              )}
                            />
                          </Grid>
                          {/* <Grid item xs={12}>
                            <Typography>Note</Typography>
                            <TextField
                              id="outlined-multiline-static"
                              multiline
                              fullWidth
                              rows={4}
                              value={note}
                              error={!!errors.note}
                              onChange={(e) => setnote(e.target.value)}
                            />
                          </Grid> */}
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              onClick={handleSubmitAddress2}
                              fullWidth
                              style={{
                                backgroundColor: "black",
                                color: "white",
                              }}
                            >
                              Submit
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    </Box>
                  </Fade>
                </Modal>
                <Modal
                  aria-labelledby="spring-modal-title"
                  aria-describedby="spring-modal-description"
                  open={OpenAddpayment}
                  onClose={handleCloseAddPayment}
                  closeAfterTransition
                  slots={{ backdrop: Backdrop }}
                  slotProps={{
                    backdrop: {
                      TransitionComponent: Fade,
                    },
                  }}
                >
                  <Fade in={OpenAddpayment}>
                    <Box sx={styleModal}>
                      <Box
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            textAlign: "left",
                          }}
                        >
                          Choose payment method
                        </Typography>
                      </Box>
                      <Collapsible
                        trigger={eWalletTrigger}
                        triggerWhenOpen={eWalletCloseTrigger}
                      >
                        <Box
                          onClick={() => handleSelectPayment("DANA", "EWALLET")}
                          sx={{
                            marginTop: "24px",
                            minHeight: "96px",
                            height: "auto",
                            width: "99%",
                            borderRadius: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            border:
                              selectedPayment === "DANA"
                                ? "4px solid rgb(254, 138, 1)"
                                : "2px solid black",
                            "&:hover": {
                              bgcolor: "rgba(0, 0, 0, 0.23)",
                            },
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              textAlign: "left",
                              padding: "20px",
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              DANA
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={logoDana}
                                alt="Dana Logo"
                                width={120}
                                style={{ transition: "background-color 0.3s" }} // Add smooth transition
                              />
                            </Grid>
                          </Grid>
                        </Box>

                        <Box
                          onClick={() => handleSelectPayment("OVO", "EWALLET")}
                          sx={{
                            marginTop: "24px",
                            minHeight: "96px",
                            height: "auto",
                            width: "99%",
                            borderRadius: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            border:
                              selectedPayment === "OVO"
                                ? "4px solid rgb(254, 138, 1)"
                                : "2px solid black",
                            "&:hover": {
                              bgcolor: "rgba(0, 0, 0, 0.23)",
                            },
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              textAlign: "left",
                              padding: "20px",
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              OVO
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={logoOvo}
                                alt="OVO Logo"
                                width={120}
                                style={{ transition: "background-color 0.3s" }} // Add smooth transition
                              />
                            </Grid>
                          </Grid>
                        </Box>

                        {/* <Box
                          onClick={() => handleSelectPayment("SHOPEEPAY", "EWALLET")}
                          sx={{
                            marginTop: "24px",
                            minHeight: "96px",
                            height: "auto",
                            width: "99%",
                            borderRadius: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            border:
                              selectedPayment === "SHOPEEPAY"
                                ? "4px solid rgb(254, 138, 1)"
                                : "2px solid black",
                            "&:hover": {
                              bgcolor: "rgba(0, 0, 0, 0.23)",
                            },
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              textAlign: "left",
                              padding: "20px",
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              Shopee Pay
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={logoShopeePay}
                                alt="Shopee Pay Logo"
                                width={120}
                                style={{ transition: "background-color 0.3s" }} // Add smooth transition
                              />
                            </Grid>
                          </Grid>
                        </Box>

                        <Box
                          onClick={() => handleSelectPayment("LINKAJA", "EWALLET")}
                          sx={{
                            marginTop: "24px",
                            minHeight: "96px",
                            height: "auto",
                            width: "99%",
                            borderRadius: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            border:
                              selectedPayment === "LINKAJA"
                                ? "4px solid rgb(254, 138, 1)"
                                : "2px solid black",
                            "&:hover": {
                              bgcolor: "rgba(0, 0, 0, 0.23)",
                            },
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              textAlign: "left",
                              padding: "20px",
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              Link Aja
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={logoLinkAja}
                                alt="Link Aja Logo"
                                width={120}
                                style={{ transition: "background-color 0.3s" }} // Add smooth transition
                              />
                            </Grid>
                          </Grid>
                        </Box>

                        <Box
                          onClick={() => handleSelectPayment("ASTRAPAY", "EWALLET")}
                          sx={{
                            marginTop: "24px",
                            minHeight: "96px",
                            height: "auto",
                            width: "99%",
                            borderRadius: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            border:
                              selectedPayment === "ASTRAPAY"
                                ? "4px solid rgb(254, 138, 1)"
                                : "2px solid black",
                            "&:hover": {
                              bgcolor: "rgba(0, 0, 0, 0.23)",
                            },
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              textAlign: "left",
                              padding: "20px",
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              Astra Pay
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={logoAstraPay}
                                alt="Astra Pay Logo"
                                width={120}
                                style={{ transition: "background-color 0.3s" }} // Add smooth transition
                              />
                            </Grid>
                          </Grid>
                        </Box>

                        <Box
                          onClick={() => handleSelectPayment("JENIUSPAY", "EWALLET")}
                          sx={{
                            marginTop: "24px",
                            minHeight: "96px",
                            height: "auto",
                            width: "99%",
                            borderRadius: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            border:
                              selectedPayment === "JENIUSPAY"
                                ? "4px solid rgb(254, 138, 1)"
                                : "2px solid black",
                            "&:hover": {
                              bgcolor: "rgba(0, 0, 0, 0.23)",
                            },
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              textAlign: "left",
                              padding: "20px",
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              Jenius Pay
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={logoJeniusPay}
                                alt="Jenius Pay Logo"
                                width={120}
                                style={{ transition: "background-color 0.3s" }} // Add smooth transition
                              />
                            </Grid>
                          </Grid>
                        </Box> */}
                      </Collapsible>

                      <Collapsible
                        trigger={bankTransferTrigger}
                        triggerWhenOpen={bankTransferCloseTrigger}
                      >
                        {/* <Box
                          onClick={() => handleSelectPayment("BCA", "VIRTUAL_ACCOUNT")}
                          sx={{
                            marginTop: "24px",
                            minHeight: "96px",
                            height: "auto",
                            width: "99%",
                            borderRadius: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            border:
                              selectedPayment === "BCA"
                                ? "4px solid rgb(254, 138, 1)"
                                : "2px solid black",
                            "&:hover": {
                              bgcolor: "rgba(0, 0, 0, 0.23)",
                            },
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              textAlign: "left",
                              padding: "20px",
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              BCA Virtual Account
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={logoBca}
                                alt="BCA Logo"
                                height={60}
                                style={{ transition: "background-color 0.3s" }} // Add smooth transition
                              />
                            </Grid>
                          </Grid>
                        </Box> */}

                        <Box
                          onClick={() =>
                            handleSelectPayment("MANDIRI", "VIRTUAL_ACCOUNT")
                          }
                          sx={{
                            marginTop: "24px",
                            minHeight: "96px",
                            height: "auto",
                            width: "99%",
                            borderRadius: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            border:
                              selectedPayment === "MANDIRI"
                                ? "4px solid rgb(254, 138, 1)"
                                : "2px solid black",
                            "&:hover": {
                              bgcolor: "rgba(0, 0, 0, 0.23)",
                            },
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              textAlign: "left",
                              padding: "20px",
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              Mandiri Virtual Account
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={logoMandiri}
                                alt="Mandiri Logo"
                                width={120}
                                style={{ transition: "background-color 0.3s" }} // Add smooth transition
                              />
                            </Grid>
                          </Grid>
                        </Box>

                        <Box
                          onClick={() =>
                            handleSelectPayment("BRI", "VIRTUAL_ACCOUNT")
                          }
                          sx={{
                            marginTop: "24px",
                            minHeight: "96px",
                            height: "auto",
                            width: "99%",
                            borderRadius: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            border:
                              selectedPayment === "BRI"
                                ? "4px solid rgb(254, 138, 1)"
                                : "2px solid black",
                            "&:hover": {
                              bgcolor: "rgba(0, 0, 0, 0.23)",
                            },
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              textAlign: "left",
                              padding: "20px",
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              BRI Virtual Account
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={logoBri}
                                alt="BRI Logo"
                                height={60}
                                style={{ transition: "background-color 0.3s" }} // Add smooth transition
                              />
                            </Grid>
                          </Grid>
                        </Box>

                        <Box
                          onClick={() =>
                            handleSelectPayment("BNI", "VIRTUAL_ACCOUNT")
                          }
                          sx={{
                            marginTop: "24px",
                            minHeight: "96px",
                            height: "auto",
                            width: "99%",
                            borderRadius: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            border:
                              selectedPayment === "BNI"
                                ? "4px solid rgb(254, 138, 1)"
                                : "2px solid black",
                            "&:hover": {
                              bgcolor: "rgba(0, 0, 0, 0.23)",
                            },
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              textAlign: "left",
                              padding: "20px",
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              BNI Virtual Account
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={logoBni}
                                alt="BNI Logo"
                                height={60}
                                style={{ transition: "background-color 0.3s" }} // Add smooth transition
                              />
                            </Grid>
                          </Grid>
                        </Box>

                        <Box
                          onClick={() =>
                            handleSelectPayment("CIMB", "VIRTUAL_ACCOUNT")
                          }
                          sx={{
                            marginTop: "24px",
                            minHeight: "96px",
                            height: "auto",
                            width: "99%",
                            borderRadius: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            border:
                              selectedPayment === "CIMB"
                                ? "4px solid rgb(254, 138, 1)"
                                : "2px solid black",
                            "&:hover": {
                              bgcolor: "rgba(0, 0, 0, 0.23)",
                            },
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              textAlign: "left",
                              padding: "20px",
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              CIMB Virtual Account
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={logoCimb}
                                alt="CIMB Logo"
                                height={60}
                                style={{ transition: "background-color 0.3s" }} // Add smooth transition
                              />
                            </Grid>
                          </Grid>
                        </Box>

                        <Box
                          onClick={() =>
                            handleSelectPayment("PERMATA", "VIRTUAL_ACCOUNT")
                          }
                          sx={{
                            marginTop: "24px",
                            minHeight: "96px",
                            height: "auto",
                            width: "99%",
                            borderRadius: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            border:
                              selectedPayment === "PERMATA"
                                ? "4px solid rgb(254, 138, 1)"
                                : "2px solid black",
                            "&:hover": {
                              bgcolor: "rgba(0, 0, 0, 0.23)",
                            },
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              textAlign: "left",
                              padding: "20px",
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              Permata Virtual Account
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={logoPermata}
                                alt="Permata Logo"
                                heigth={60}
                                style={{ transition: "background-color 0.3s" }} // Add smooth transition
                              />
                            </Grid>
                          </Grid>
                        </Box>

                        <Box
                          onClick={() =>
                            handleSelectPayment(
                              "SAHABAT_SAMPOERNA",
                              "VIRTUAL_ACCOUNT"
                            )
                          }
                          sx={{
                            marginTop: "24px",
                            minHeight: "96px",
                            height: "auto",
                            width: "99%",
                            borderRadius: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            border:
                              selectedPayment === "SAHABAT_SAMPOERNA"
                                ? "4px solid rgb(254, 138, 1)"
                                : "2px solid black",
                            "&:hover": {
                              bgcolor: "rgba(0, 0, 0, 0.23)",
                            },
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              textAlign: "left",
                              padding: "20px",
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              Sampoerna Virtual Account
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={logoSampoerna}
                                alt="Sampoerna Logo"
                                height={60}
                                style={{ transition: "background-color 0.3s" }} // Add smooth transition
                              />
                            </Grid>
                          </Grid>
                        </Box>

                        <Box
                          onClick={() =>
                            handleSelectPayment("BJB", "VIRTUAL_ACCOUNT")
                          }
                          sx={{
                            marginTop: "24px",
                            minHeight: "96px",
                            height: "auto",
                            width: "99%",
                            borderRadius: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            border:
                              selectedPayment === "BJB"
                                ? "4px solid rgb(254, 138, 1)"
                                : "2px solid black",
                            "&:hover": {
                              bgcolor: "rgba(0, 0, 0, 0.23)",
                            },
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              textAlign: "left",
                              padding: "20px",
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              BJB Virtual Account
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={logoBjb}
                                alt="BJB Logo"
                                heigth={60}
                                style={{ transition: "background-color 0.3s" }} // Add smooth transition
                              />
                            </Grid>
                          </Grid>
                        </Box>

                        <Box
                          onClick={() =>
                            handleSelectPayment("BSI", "VIRTUAL_ACCOUNT")
                          }
                          sx={{
                            marginTop: "24px",
                            minHeight: "96px",
                            height: "auto",
                            width: "99%",
                            borderRadius: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            border:
                              selectedPayment === "BSI"
                                ? "4px solid rgb(254, 138, 1)"
                                : "2px solid black",
                            "&:hover": {
                              bgcolor: "rgba(0, 0, 0, 0.23)",
                            },
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              textAlign: "left",
                              padding: "20px",
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              BSI Virtual Account
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={logoBsi}
                                alt="BSI Logo"
                                height={60}
                                style={{ transition: "background-color 0.3s" }} // Add smooth transition
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Collapsible>

                      <Collapsible
                        trigger={qrisTrigger}
                        triggerWhenOpen={qrisCloseTrigger}
                      >
                        <Box
                          onClick={() => handleSelectPayment("QRIS", "QR_CODE")}
                          sx={{
                            marginTop: "24px",
                            minHeight: "96px",
                            height: "auto",
                            width: "99%",
                            borderRadius: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            border:
                              selectedPayment === "QRIS"
                                ? "4px solid rgb(254, 138, 1)"
                                : "2px solid black",
                            "&:hover": {
                              bgcolor: "rgba(0, 0, 0, 0.23)",
                            },
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              textAlign: "left",
                              padding: "20px",
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              QRIS
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={logoQris}
                                alt="QRIS Logo"
                                width={90}
                                style={{ transition: "background-color 0.3s" }} // Add smooth transition
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Collapsible>

                      <Box
                          onClick={() => handleSelectPayment("COD", "COD")}
                          sx={{
                            marginTop: "24px",
                            minHeight: "96px",
                            height: "auto",
                            width: "99%",
                            borderRadius: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            border:
                              selectedPayment === "COD"
                                ? "4px solid rgb(254, 138, 1)"
                                : "2px solid black",
                            "&:hover": {
                              bgcolor: "rgba(0, 0, 0, 0.23)",
                            },
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              textAlign: "left",
                              padding: "20px",
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              Cash on Delivery (COD)
                            </Grid>
                          </Grid>
                        </Box>

                      {/* <Box
                        sx={{
                          marginTop: "24px",
                          minHeight: "96px",
                          height: "auto",
                          width: "100%",
                          borderRadius: "20px",
                          border:
                            selectedPayment === "BCA"
                              ? "4px solid rgb(254, 138, 1)"
                              : "2px solid black",
                          "&:hover": {
                            bgcolor: "rgba(0, 0, 0, 0.23)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-start",
                          },
                        }}
                      >
                        <Grid
                          container
                          sx={{
                            textAlign: "left",
                            padding: "20px",
                          }}
                        >
                          <Grid
                            item
                            xs={8}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            DANA
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            LOGO DANA
                          </Grid>
                        </Grid>
                      </Box>
                      <Box
                        sx={{
                          marginTop: "24px",
                          minHeight: "96px",
                          height: "auto",
                          width: "100%",
                          borderRadius: "20px",
                          border:
                            selectedPayment === "SAKU"
                              ? "4px solid rgb(254, 138, 1)"
                              : "2px solid black",
                          "&:hover": {
                            bgcolor: "rgba(0, 0, 0, 0.23)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-start",
                          },
                        }}
                      >
                        <Grid
                          container
                          sx={{
                            textAlign: "left",
                            padding: "20px",
                          }}
                        >
                          <Grid
                            item
                            xs={8}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            DANA
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            LOGO DANA
                          </Grid>
                        </Grid>
                      </Box> */}
                      <Box
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Grid
                          container
                          sx={{
                            marginTop: "20px",
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"flex-end"}
                          >
                            <Button
                              style={btnPaymentStyle}
                              onClick={handleAdminFee}
                            >
                              {isLoadingPaymentMethod ? (
                                <>
                                  <CircularProgress size={24} color="inherit" />{" "}
                                  {/* Loading spinner */}
                                  Processing...
                                </>
                              ) : (
                                <div>Choose Payment</div>
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Fade>
                </Modal>
                <Modal
                  aria-labelledby="spring-modal-title"
                  aria-describedby="spring-modal-description"
                  open={OpenChooseDelivery}
                  onClose={handleCloseChooseDelivery}
                  closeAfterTransition
                  slots={{ backdrop: Backdrop }}
                  slotProps={{
                    backdrop: {
                      TransitionComponent: Fade,
                    },
                  }}
                >
                  <Fade in={OpenChooseDelivery}>
                    <Box sx={styleModal}>
                      <Box
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            textAlign: "left",
                          }}
                        >
                          Choose Delivery
                        </Typography>
                      </Box>

                      {/* <Collapsible
                        trigger={jntTrigger}
                        triggerWhenOpen={jntCloseTrigger}
                      >
                        <Box
                          onClick={() => handleSelectDeliveryMethod("JNT", "COD")}
                          sx={{
                            marginTop: "24px",
                            minHeight: "96px",
                            height: "auto",
                            width: "99%",
                            borderRadius: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            border:
                              selectedDelivery === "JNT_COD"
                                ? "4px solid rgb(254, 138, 1)"
                                : "2px solid black",
                            "&:hover": {
                              bgcolor: "rgba(0, 0, 0, 0.23)",
                            },
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              textAlign: "left",
                              padding: "20px",
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              Cash on Delivery (COD)
                            </Grid>
                          </Grid>
                        </Box>
                        <Box
                          onClick={() => handleSelectDeliveryMethod("JNT", "REG")}
                          sx={{
                            marginTop: "24px",
                            minHeight: "96px",
                            height: "auto",
                            width: "99%",
                            borderRadius: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            border:
                              selectedDelivery === "JNT_REG"
                                ? "4px solid rgb(254, 138, 1)"
                                : "2px solid black",
                            "&:hover": {
                              bgcolor: "rgba(0, 0, 0, 0.23)",
                            },
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              textAlign: "left",
                              padding: "20px",
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              Regular
                            </Grid>
                          </Grid>
                        </Box>
                      </Collapsible> */}

                      <Box
                          onClick={() => handleSelectDeliveryMethod("JNT", "")}
                          sx={{
                            marginTop: "24px",
                            minHeight: "96px",
                            height: "auto",
                            width: "99%",
                            borderRadius: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            border:
                              selectedExpedition === "JNT"
                                ? "4px solid rgb(254, 138, 1)"
                                : "2px solid black",
                            "&:hover": {
                              bgcolor: "rgba(0, 0, 0, 0.23)",
                            },
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              textAlign: "left",
                              padding: "20px",
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              JNT
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={logoJnt}
                                alt="JNT Logo"
                                width={90}
                                style={{ transition: "background-color 0.3s" }} // Add smooth transition
                              />
                            </Grid>
                          </Grid>
                        </Box>

                      <Box
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Grid
                          container
                          sx={{
                            marginTop: "20px",
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"flex-end"}
                          >
                            <Button
                              style={btnPaymentStyle}
                              onClick={handleCheckDelivery}
                            >
                              {isLoadingDelivery ? (
                                <>
                                  <CircularProgress size={24} color="inherit" />{" "}
                                  Processing...
                                </>
                              ) : (
                                <div>Choose Delivery</div>
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Fade>
                </Modal>
                <Box sx={{ padding: 2, margin: 4, color: "white" }}>
                  {/* <Typography variant="h4">Payment Summary</Typography> */}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sx={{ paddingLeft: "24px" }}>
                      {isLoadingOrder ? (
                        <div>Loading...</div> // Show loading indicator
                      ) : (
                        filteredOrders.map((order, index) => (
                          <React.Fragment key={index}>
                            <Grid container spacing={3} marginTop={1}>
                              <Grid item xs={3}>
                                <div style={{ width: "100%", aspectRatio: "6 / 4" }}>
                                  <img
                                    src={
                                      order.itemList[0].images?.length <= 0 || order.itemList[0].images == null
                                        ? noImage
                                        : `${imageUrl}${order.itemList[0].images[0]}`
                                    }
                                    alt={order.itemList[0].name}
                                    style={{
                                      width: "100%",
                                      aspectRatio: "6 / 4",
                                      objectFit: "cover",
                                    }}
                                  ></img>
                                </div>
                              </Grid>
                              <Grid item xs={8}>
                                <Grid container>
                                  {
                                    order.itemList[0].name ? (
                                      <Grid item xs={12} sx={{ paddingLeft: "24px" }}>
                                        <Typography
                                          variant="h4"
                                          style={{
                                            textAlign: "right",
                                            fontWeight: "bolder",
                                          }}
                                        >
                                          {order.itemList[0].name}
                                        </Typography>
                                      </Grid>
                                    ) : ''
                                  }
                                  <Grid item xs={12} sx={{ paddingLeft: "24px" }}>
                                    <Typography
                                      variant="h4"
                                      style={{
                                        textAlign: "right",
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      {order.itemList[0].typeName} -{" "}
                                      {order.itemList[0].typeVariant}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sx={{ paddingLeft: "24px" }}>
                                    <Typography variant="h6" style={{ textAlign: "right" }}>
                                      {order.id}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sx={{ paddingLeft: "24px" }}>
                                    <Typography variant="h4" style={{ textAlign: "right" }}>
                                      <NumericFormat
                                        value={order.price}
                                        displayType={"text"}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        prefix={"Rp. "}
                                      />
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={1} display="flex" alignItems="center">
                                <IconButton aria-label="remove" onClick={() => handleOpenDelete(order.id)}>
                                  <RemoveCircleIcon style={{ color: "#E50B0B" }}/>
                                </IconButton>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        ))
                      )}
                    </Grid>
                  </Grid>

                  <Grid container marginTop={5}>
                    <Grid item xs={12} style={{
                      border: "1px solid white",
                      borderTopLeftRadius: "15px",
                      borderTopRightRadius: "15px",
                      minHeight: "100px",
                      display: "flex",
                      alignItems: "center",
                      padding: "15px",
                      justifyContent: "space-between"
                    }}>
                      <Typography variant="h5">Choose Payment</Typography>
                      <Button
                        style={selectedPayment ? btnMethodSelectedStyle : btnMethodStyle}
                        onClick={handleOpenAddPayment}
                        endIcon={<ArrowDropDownIcon />}
                      >
                        {selectedPayment ? (paymentMethod === "VIRTUAL_ACCOUNT" ? selectedPayment + " VA" : selectedPayment) : "Choose Payment"}
                      </Button>
                    </Grid>
                    <Grid item xs={12} style={{
                      border: "1px solid white",
                      borderTop: "none",
                      minHeight: "100px",
                      display: "flex",
                      alignItems: "center",
                      padding: "15px",
                      justifyContent: "space-between",
                      gap: "5px"
                    }}>
                      <Typography variant="h5">Choose Delivery</Typography>
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                        <div>
                          <Button
                            style={selectedExpedition ? btnMethodSelectedStyle : btnMethodStyle}
                            endIcon={<ArrowDropDownIcon />}
                            onClick={handleOpenChooseDelivery}
                          >
                            {selectedExpedition ? selectedExpedition : "Choose Delivery"}
                          </Button>
                        </div>
                        <div style={{ paddingRight: "10px" }}>
                          {/* <Typography variant="p">Estimasi barang tiba 30 November</Typography> */}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} style={{
                      border: "1px solid white",
                      borderTop: "none",
                      borderBottomLeftRadius: "15px",
                      borderBottomRightRadius: "15px",
                      minHeight: "70px",
                      display: "flex",
                      alignItems: "center",
                      padding: "15px",
                      justifyContent: "space-between"
                    }}>
                      <Typography variant="h5">Voucher</Typography>
                      <Button style={btnMethodStyle} endIcon={<ArrowDropDownIcon />}>
                        Choose Voucher
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid container marginTop={5}>
                    <Grid item xs={12} style={{
                      border: "1px solid white",
                      borderTopLeftRadius: "15px",
                      borderTopRightRadius: "15px",
                      minHeight: "100px",
                      padding: "15px",
                    }}>
                      <Typography variant="h5">Payment Summary</Typography>

                      <Grid container marginTop={3} style={{ alignItems: 'center' }}>
                        <Grid item xs={6}>
                          <Typography variant="h6">Product Price</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <React.Fragment>
                            <Typography align="right" variant="h6">
                              <NumericFormat
                                value={
                                  isLoadingOrder
                                    ? "Loading..."
                                    : !totalPrice
                                      ? "-"
                                      : totalPrice
                                }
                                displayType={"text"}
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix={"Rp. "}
                              />
                            </Typography>
                          </React.Fragment>
                        </Grid>

                        {cost ? (
                          cost.map((result, index) => (
                            <React.Fragment key={index}>
                              {result.costs.map((costDetail, idx) => (
                                <React.Fragment key={`${index}-${idx}`}>
                                  <Grid item xs={6}>
                                    <Typography variant="h6">
                                      Delivery Fee (ID Express)
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography
                                      variant="h6"
                                      style={{ textAlign: "right" }}
                                    >
                                      {formatCurrency(costDetail.cost[0].value)}
                                    </Typography>
                                  </Grid>
                                </React.Fragment>
                              ))}
                            </React.Fragment>
                          ))
                        ) : (
                          <>
                            <Grid item xs={6}>
                              <Typography variant="h6">Shipping Cost</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <React.Fragment>
                                <Typography align="right" variant="h6">
                                  <NumericFormat
                                    value={
                                      isLoadingPayment
                                        ? "Loading..."
                                        : !paymentData || !paymentData.deliveryFee
                                          ? "-"
                                          : paymentData.deliveryFee
                                    }
                                    displayType={"text"}
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    prefix={"Rp. "}
                                  />
                                </Typography>
                              </React.Fragment>
                            </Grid>
                          </>
                        )}

                        <Grid item xs={6}>
                          <Typography variant="h6">Admin fee</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <React.Fragment>
                            <Typography align="right" variant="h6">
                              <NumericFormat
                                value={
                                  isLoadingPayment
                                    ? "Loading..."
                                    : !paymentData || !paymentData.transactionFee
                                      ? "-"
                                      : paymentData.transactionFee
                                }
                                displayType={"text"}
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix={"Rp. "}
                              />
                            </Typography>
                          </React.Fragment>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} style={{ 
                      border: "1px solid white",
                      borderTop: "none",
                      borderBottomLeftRadius: "15px",
                      borderBottomRightRadius: "15px",
                      minHeight: "70px",
                      padding: "15px",
                    }}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant="h5" fontWeight={"bolder"}>
                            Total
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <React.Fragment>
                            <Typography
                              variant="h5"
                              align="right"
                              style={{ fontWeight: "bolder" }}
                            >
                              <NumericFormat
                                value={
                                  isLoadingPayment
                                    ? "Loading..."
                                    : !paymentData || !paymentData.totalPrice
                                      ? "-"
                                      : paymentData.totalPrice
                                }
                                displayType={"text"}
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix={"Rp. "}
                              />
                            </Typography>
                          </React.Fragment>
                        </Grid>
                        <Grid item xs={12} marginTop={2} 
                          style={{ display: "flex", justifyContent: "end", alignItems: "end" }}
                        >
                          <Button style={btnPaymentStyle} onClick={handlePayment}>
                            {loading ? (
                              <>
                                <CircularProgress size={24} color="inherit" />{" "}
                                {/* Loading spinner */}
                                Processing...
                              </>
                            ) : (
                              "Proceed to Pay"
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                      
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Footer />

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openDelete}
        onClose={handleCloseDelete}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openDelete}>
          <Box sx={styleModal}>
            <Typography id="spring-modal-title" variant="h6" component="h2">
              Apakah anda yakin ingin membatalkan pesanan untuk produk ini?
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Button
                sx={{ backgroundColor: "#E50B0B", color: "white" }}
                onClick={deleteItem}
                disabled={loadingDelete}
              >
                Batalkan Pesanan
              </Button>
              <Button
                variant="outlined"
                onClick={handleCloseDelete}
                sx={{
                  ml: 2,
                  backgroundColor: "#FE8A01",
                  color: "white",
                }}
              >
                Tidak
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>

    </>
  );
}
