import React, { useEffect, useState } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import './carousel.css'; // For styling
import noImage from "../../../assets/no-image.png";
import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const BannerCarousel = ({images, title, description, navigateTo}) => {

  const [currentIndex, setCurrentIndex] = useState(0);
  const iamgeUrl = "https://api.liquestore.com/assets/items/"
  const navigate = useNavigate();

  const btnMoreStyle = {
    borderRadius: "100px",
    width: "150px",
    maxWidth: "100%",
    height: "40px",
    backgroundColor: "#fff",
    color: "black",
    padding: 5,
    textTransform: "none",
    fontSize: "1em",
  };

  const goToPrevious = () => {
    setCurrentIndex(currentIndex === 0 ? images.length - 1 : currentIndex - 1);
  };

  const goToNext = () => {
    if (images.length <= 0) {
      setCurrentIndex(0)
    } else {
      setCurrentIndex(currentIndex === images.length - 1 ? 0 : currentIndex + 1);
    }
  };

  useEffect(() => {
    const interval = setInterval(goToNext, 10000); // Slide every 10 seconds
    return () => clearInterval(interval); // Clear interval on component unmount
  }, [currentIndex]);

  return (
    <div className="carousel">
      <button onClick={goToPrevious} className="carousel-button">
        <KeyboardArrowLeftIcon />
      </button>
      <div className="carousel-track-wrapper">

        <div className="carousel-info">
          <Grid container sx={{ marginBottom: { xs: "10px", sm: "20px", md: "30px" } }}>
            <Grid item xs={12} paddingX="50px" textAlign="center">
              <Typography variant='h5' fontWeight="bold">{title}</Typography>
            </Grid>
            <Grid item xs={12} paddingX="50px" textAlign="center">
              <Typography variant='p'>{description}</Typography>
            </Grid>
            <Grid item xs={12} paddingX="50px" marginTop="10px" textAlign="center">
              <Button style={btnMoreStyle} onClick={() => navigate(navigateTo)}>SHOP NOW</Button>
            </Grid>
          </Grid>
        </div>

        <div
          className="carousel-track"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
          {images.map((src, index) => (
            <div className='carousel-image-container' key={index}>
              <div className='carousel-image-cover'></div>
              <img src={iamgeUrl + src} alt={`img ${index + 1}`} className="carousel-image" />
            </div>
          ))}

          {
            images.length <= 0 && (
              <div className='carousel-image-container'>
                <div className='carousel-image-cover'></div>
                <img src={noImage} alt="no_image" className="carousel-image" />
              </div>
            )
          }
        </div>
      </div>
      <button onClick={goToNext} className="carousel-button">
        <KeyboardArrowRightIcon />
      </button>
    </div>
  );
};

export default BannerCarousel;
