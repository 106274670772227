import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import Multiselect from "multiselect-react-dropdown";
import axios from "axios";
import styled from "styled-components";
import { PDFDownloadLink } from "@react-pdf/renderer";
import "./gajiKaryawan.css";
import SalarySlipPDF from "./gajiKaryawanSlipPDF";
import {
  Alert,
  Autocomplete,
  Backdrop,
  Button,
  CssBaseline,
  Drawer,
  Fade,
  FormControl,
  Grid,
  Modal,
  TextField,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import SupervisorSidebar from "./sidebar";
import {
  AccountCircle,
  ChevronLeft,
  ChevronRight,
  Phone,
} from "@mui/icons-material";
import { addMonths, subMonths } from "date-fns";
import { useAuth } from "../authContext";
import EditIcon from "@mui/icons-material/Edit";

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const styleModalBesar = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxHeight: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
  overflowY: "auto",
};

const btnCetakSlipGaji = {
  justifyContent: "center",
  width: "15vw",
  borderRadius: "10px",
  backgroundColor: "#FE8A01",
  color: "black",
  border: "3px solid black",
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const headCells = [
  { id: "date", numeric: false, disablePadding: false, label: "Tanggal" },
  { id: "clockIn", numeric: false, disablePadding: false, label: "Clock-In" },
  { id: "clockOut", numeric: false, disablePadding: false, label: "Clock-Out" },
  {
    id: "hoursWorked",
    numeric: true,
    disablePadding: false,
    label: "Jam Kerja",
  },
  {
    id: "basePay",
    numeric: true,
    disablePadding: false,
    label: "Gaji Pokok",
  },
  {
    id: "foodAllowance",
    numeric: false,
    disablePadding: false,
    label: "Uang Makan",
  },
  {
    id: "overtimePay",
    numeric: false,
    disablePadding: false,
    label: "Uang Lembur",
  },
  {
    id: "offPay",
    numeric: false,
    disablePadding: false,
    label: "Gaji Libur",
  },
  {
    id: "lateDeduction",
    numeric: false,
    disablePadding: false,
    label: "Terlambat",
  },
  {
    id: "attendanceStatus",
    numeric: false,
    disablePadding: false,
    label: "Keterangan",
  },
  {
    id: "netPay",
    numeric: false,
    disablePadding: false,
    label: "Total Gaji",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Employee Data
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const idrFormat = new Intl.NumberFormat("en-ID", {
  style: "currency",
  currency: "IDR",
});

const formatCurrency = (number) => {
  if (!number) number = 0;
  return idrFormat.format(number);
};

export default function GajiKaryawan() {
  const drawerWidth = 300;
  var visibleRows = [];

  const { auth, logout } = useAuth();
  const getUsername = auth.user ? auth.user.username : "";
  const [order, setOrder] = useState("asc");
  const [filteredData, setFilteredData] = useState(visibleRows);
  const [orderBy, setOrderBy] = useState("tanggal");
  const [searchTerm, setSearchTerm] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [openLogout, setOpenLogout] = useState(false);

  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState();

  // State for multiselect dropdown
  const [selectedValues, setSelectedValues] = useState([]);

  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [currentEmployee, setCurrentEmployee] = useState({});
  const [currentEmployeePayDetail, setCurrentEmployeePayDetail] = useState({});
  const [scheduledClockIn, setScheduledClockIn] = useState("");
  const [offDay, setOffDay] = useState("");
  const [payslip, setPayslip] = useState({});
  const [salarySlipPDFFileName, setSalarySlipPDFFileName] = useState("");
  const [employeMonthlyAdditionalPay, setEmployeMonthlyAdditionalPay] = useState([]);
  const [employeeDebitMonthlyAdditionalPay, setEmployeeDebitMonthlyAdditionalPay] = useState([]);
  const [employeeCreditMonthlyAdditionalPay, setEmployeeCreditMonthlyAdditionalPay] = useState([]);

  // Update employee attendance form values
  const [editAttendanceOpen, setEditAttendanceOpen] = useState(false);
  const [attendanceDate, setAttendanceDate] = useState("");
  const [attendanceClockIn, setAttendanceClockIn] = useState("");
  const [attendanceClockOut, setAttendanceClockOut] = useState("");
  const [attendanceStatus, setAttendanceStatus] = useState("");
  const [attendanceStatusOptions, setAttendanceStatusOptions] = useState([]);

  const [transactions, setTransactions] = useState([]);
  const [totalSalary, setTotalSalary] = useState(0);
  const [salaryBalance, setSalaryBalance] = useState(0);

  const [id, setId] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  
  const handleCloseDelete = () => setOpenDelete(false);


  const months = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];
  const currentMonthName = months[currentDate.getMonth()];
  const currentYear = currentDate.getFullYear();

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const onSelect = (selectedList, selectedItem) => {
    setSelectedValues(selectedList);
  };

  const onRemove = (selectedList, removedItem) => {
    setSelectedValues(selectedList);
  };

  // Log out handlers
  const handleLogout = () => {
    setOpenLogout(false);
    logout();
  };

  const handleOpenLogout = () => setOpenLogout(true);

  const handleCloseLogout = () => setOpenLogout(false);

  // Handle Salary Slip PDF File Name
  const handleSalarySlipPDFFileName = () => {
    setSalarySlipPDFFileName(
      `${currentDate.getFullYear()}_${
        currentDate.getMonth() + 1
      }_${currentEmployee?.fullname
        ?.toUpperCase()
        ?.replaceAll(" ", "-")}_PAYSLIP.pdf`
    );
  };

  // Table handlers
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Selected employee handlers
  const handleEmployeeChange = (event, newValue) => {
    setCurrentEmployee(newValue);
    const employeePayData = {
      data: newValue?.payDetail,
    };
  };

  // Selected date handlers
  const handlePreviousMonth = () => {
    setCurrentDate(subMonths(currentDate, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1));
  };

  // Edit employee attendance handlers
  const handleOpenEditEmployeeAttendance = (payDetail) => {
    setAttendanceDate(payDetail.date);
    setAttendanceClockIn(payDetail.clockIn);
    setAttendanceClockOut(payDetail.clockOut);
    setAttendanceStatus(payDetail.attendanceStatus);
    setSelectedValues(
      payDetail.attendanceStatus.split(",").map((item) => ({
        id: item,
        label: item,
      }))
    );
    setEditAttendanceOpen(true);
  };

  const fetchPayslip = async (employeeId, month, year) =>
    axios.get(
      `${process.env.REACT_APP_ENDPOINTS_EMPLOYEES_SERVICE}/${currentEmployee.id}/monthly-payslip?month=${month}&year=${year}`
    );

  const handleUpdateEmployeeAttendance = async () => {
    try {
      const response = await axios
        .put(
          `${process.env.REACT_APP_ENDPOINTS_EMPLOYEES_SERVICE}/${currentEmployee.id}/attendance`,
          {
            date: attendanceDate,
            clockIn: attendanceClockIn,
            clockOut: attendanceClockOut,
            attendanceStatus: selectedValues.map((item) => item.label).join(),
          }
        )
        .then((res) => res.data);

      const month = currentDate.getMonth() + 1;
      const year = currentDate.getFullYear();

      fetchPayslip(currentEmployee.id, month, year).then((res) =>
        setPayslip(res.data)
      );
      setEditAttendanceOpen(false);
      setSelectedValues([]);
    } catch (error) {
      setShowError(true);
      setMsgError(error.response.data.message);
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    }
  };

  const [newTransaction, setNewTransaction] = useState({
    name: '',
    amount: '',
    type: 'DEBIT',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTransaction((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddTransaction = async () => {
    setLoading(true);
    setError(null);

    const transactionData = {
      employeeId: currentEmployee.id,
      month: currentDate.getMonth() + 1,
      year: currentYear,
      name: newTransaction.name,
      amount: parseFloat(newTransaction.amount),
      type: newTransaction.type,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINTS_EMPLOYEES_SERVICE}/monthly-additional-pay`,
        JSON.stringify(transactionData), {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        if (newTransaction.type === "DEBIT") {
          fetchAdditionalPayData();
          setEmployeeDebitMonthlyAdditionalPay((prev) => [
            ...prev,
            { name: newTransaction.name, amount: transactionData.amount },
          ]);

          const updatedTransactions = [...transactions, transactionData];
          setTransactions(updatedTransactions);

          updateTotalSalary(updatedTransactions);
        } else if (newTransaction.type === "CREDIT") {
          setEmployeeCreditMonthlyAdditionalPay((prev) => [
            ...prev,
            { name: newTransaction.name, amount: transactionData.amount },
          ]);
        }

        // Clear form after submission
        setNewTransaction({ name: "", amount: "", type: "DEBIT" });
      } else {
        setError("Failed to add transaction. Please try again.");
      }
    } catch (error) {
      setError("An error occurred while adding the transaction.");
    } finally {
      setLoading(false); // Stop the loading indicator
    }
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.delete(`${process.env.REACT_APP_ENDPOINTS_EMPLOYEES_SERVICE}/monthly-additional-pay/${id}`);

      if (response.status === 200) {
        fetchAdditionalPayData();
        
        const updatedTransactions = transactions.filter((transaction) => transaction.id !== id);
        setTransactions(updatedTransactions);

        updateTotalSalary(updatedTransactions);
      } else {
        setError(`Error: ${response.status} - ${response.data.message || 'Unknown error'}`);
      }
    } catch (error) {
      if (error.response) {
        setError(`Error: ${error.response.status} - ${error.response.data.message || 'Server error'}`);
      } else {
        setError('Error: No response from server');
      }
    } finally {
      setLoading(false);
      setOpenDelete(false);
    }
  };

  const handleOpenDelete = (id) => {
    setId(id);
    setOpenDelete(true);
  };

  const updateTotalSalary = (updatedTransactions) => {
    const newTotal = updatedTransactions.reduce((acc, transaction) => {
      // If the type is CREDIT, add the amount; if DEBIT, subtract the amount
      return transaction.type === 'CREDIT'
        ? acc - transaction.amount
        : acc + transaction.amount;
    }, 0);

    setTotalSalary(newTotal);
  };

  const calculateTotalSalaryBalance = () => {
    const baseSalary = payslip?.monthlyPayGross || 0;
    const overtime = payslip?.overtimePay || 0;
    const foodAllowance = payslip?.foodAllowance || 0;
    const offPay = payslip?.offPay || 0;

    const lateDeduction = payslip?.lateDeduction || 0;
    const absentDeduction = payslip?.absentDeduction || 0;

    let total = baseSalary + offPay + overtime + foodAllowance - lateDeduction - absentDeduction;

    // let total = payslip?.monthlyPayNet || 0;

    employeeDebitMonthlyAdditionalPay.forEach(debit => {
      total += debit.amount;
    });

    employeeCreditMonthlyAdditionalPay.forEach(credit => {
      total -= credit.amount;
    });

    if (total < 0) total = 0;

    setSalaryBalance(total);
  };

  useEffect(() => {
    calculateTotalSalaryBalance();
  }, [employeeDebitMonthlyAdditionalPay, employeeCreditMonthlyAdditionalPay]);

  useEffect(() => {
    if (transactions && transactions.length > 0) {
      const newTotalSalary = calculateTotalSalary(transactions);
      const newSalaryBalance = payslip?.monthlyPayNet+newTotalSalary;
      setSalaryBalance(newSalaryBalance);
      setTotalSalary(newTotalSalary);
    }
  }, [transactions, payslip?.monthlyPayNet]);

  // Update page data when selected employee or data change
  useEffect(() => {
    if (!currentEmployee || !currentEmployee.id) return;

    const fetchData = async () => {
      const month = currentDate.getMonth() + 1;
      const year = currentDate.getFullYear();

      const url = `${process.env.REACT_APP_ENDPOINTS_EMPLOYEES_SERVICE}/${currentEmployee.id}/monthly-payslip?month=${month}&year=${year}`;
      const data = await axios.get(url).then((res) => res.data);

      setPayslip(data);
    };

    fetchData();
  }, [currentEmployee, currentDate]);

  useEffect(() => {
    if (!currentEmployee || !currentEmployee.id) return;

    const fetchData = async () => {
      const url = `${process.env.REACT_APP_ENDPOINTS_EMPLOYEES_SERVICE}/${currentEmployee.id}`;
      const data = await axios.get(url).then((res) => res.data);

      setScheduledClockIn(data.scheduledClockIn);
      setOffDay(data.offDay);
    };

    fetchData();
  }, [currentEmployee]);

  const calculateTotalSalary = (transactions) => {
    let total = 0;
  
    transactions.forEach((transaction) => {
      if (transaction.type === 'DEBIT') {
        total += transaction.amount;
      } else if (transaction.type === 'CREDIT') {
        total -= transaction.amount;
      }
    });
  
    return total;
  };

  const fetchAdditionalPayData = async () => {
    const month = currentDate.getMonth() + 1;
      const year = currentDate.getFullYear();

      const url = `${process.env.REACT_APP_ENDPOINTS_EMPLOYEES_SERVICE}/monthly-additional-pay?id=${currentEmployee.id}&month=${month}&year=${year}`;
      const data = await axios.get(url).then((res) => res.data);

      // Set the state for the additional pay data
      setEmployeMonthlyAdditionalPay(data);

      // Process the data immediately after fetching it
      const debitTransaction = data.filter(
        (transaction) => transaction.type === "DEBIT"
      );
      const creditTransaction = data.filter(
        (transaction) => transaction.type === "CREDIT"
      );

      // Update the state for debit and credit transactions
      setEmployeeDebitMonthlyAdditionalPay(debitTransaction);
      setEmployeeCreditMonthlyAdditionalPay(creditTransaction);
  }

  useEffect(() => {
    if (!currentEmployee || !currentEmployee.id) return;

    const fetchData = async () => {
      const month = currentDate.getMonth() + 1;
      const year = currentDate.getFullYear();

      const url = `${process.env.REACT_APP_ENDPOINTS_EMPLOYEES_SERVICE}/monthly-additional-pay?id=${currentEmployee.id}&month=${month}&year=${year}`;
      const data = await axios.get(url).then((res) => res.data);

      // Set the state for the additional pay data
      setEmployeMonthlyAdditionalPay(data);

      // Process the data immediately after fetching it
      const debitTransaction = data.filter(
        (transaction) => transaction.type === "DEBIT"
      );
      const creditTransaction = data.filter(
        (transaction) => transaction.type === "CREDIT"
      );

      // Update the state for debit and credit transactions
      setEmployeeDebitMonthlyAdditionalPay(debitTransaction);
      setEmployeeCreditMonthlyAdditionalPay(creditTransaction);
    };

    fetchData();
  }, [currentEmployee, currentDate]);

  useEffect(() => {
    const employeePayDetail = {
      ...currentEmployeePayDetail,
      ...payslip,
      debitTransaction: employeeDebitMonthlyAdditionalPay,
      creditTransaction: employeeCreditMonthlyAdditionalPay,
      month: currentMonthName,
      year: currentYear,
    };
    setCurrentEmployeePayDetail(employeePayDetail);
  }, [currentEmployeePayDetail, payslip, currentMonthName, currentYear, employeeDebitMonthlyAdditionalPay, employeeCreditMonthlyAdditionalPay]);

  // Fetch initial page data (employee list, attendance status list)
  useEffect(() => {
    const fetchEmployeeList = async () =>
      await axios.get(process.env.REACT_APP_ENDPOINTS_EMPLOYEES_SERVICE);
    const fetchAttendanceStatusList = async () =>
      await axios.get(
        `${process.env.REACT_APP_ENDPOINTS_EMPLOYEES_SERVICE}/attendance-status`
      );

    fetchEmployeeList().then((res) => {
      const employeeOptions = res.data.employeeList.map((e) => ({
        id: e.id,
        label: e.fullName,
        fullname: e.fullName,
        payDetail: e.payDetail,
        position: e.role,
        phoneNumber: e.phoneNumber,
        email: e.email,
      }));
      setEmployeeOptions(employeeOptions);
    });

    fetchAttendanceStatusList().then((res) => {
      const attendanceStatusOptions = res.data.attendanceStatusList.map(
        (e) => ({ id: e, label: e })
      );

      setAttendanceStatusOptions(attendanceStatusOptions);
    });
  }, []);

  useEffect(() => {
    if (currentEmployee && currentEmployeePayDetail) {
      handleSalarySlipPDFFileName();
    }
  }, [currentEmployee, currentEmployeePayDetail, handleSalarySlipPDFFileName]);

  function decideRowColor(payDetail) {
    const attendanceStatusList = payDetail?.attendanceStatus?.split(",");
    if (attendanceStatusList.length > 1) {
      if (attendanceStatusList.includes("TERLAMBAT")) {
        return "orange";
      } else if (attendanceStatusList.includes("DIBAWAH JAM KERJA")) {
        return "rgb(150, 178, 250)";
      } else if (attendanceStatusList.includes("HADIR")){
        return "white";
      }
    }
    
    switch (payDetail?.attendanceStatus?.toUpperCase()) {
      case "LIBUR":
        return "rgb(255, 255, 145)";
      case "ABSEN":
        return "rgb(236, 140, 140)";
      case "TERLAMBAT":
        return "orange";
      case "DIBAWAH JAM KERJA":
        return "rgb(150, 178, 250)";
      case "HADIR":
        return "white";
      default:
        return "white";
    }
  }

  visibleRows = React.useMemo(() => {
    if (!payslip || !payslip.dailyPayslipList) {
      return [];
    }
    const data = payslip.dailyPayslipList;

    // Apply search filter before sorting
    const filteredData = data.filter((row) =>
      Object.values(row).some((val) =>
        String(val).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

    // Sort the filtered data (assuming getComparator is a valid sorting function)
    return filteredData.sort(getComparator(order, orderBy));
  }, [payslip, searchTerm, order, orderBy]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "black",
              color: "white",
            },
          }}
          open
        >
          <SupervisorSidebar />
        </Drawer>
      </Box>

      {/* Main component */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Button
          style={{ float: "right" }}
          color="inherit"
          onClick={handleOpenLogout}
          startIcon={<AccountCircle />}
        >
          {getUsername}
        </Button>
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={openLogout}
          onClose={handleCloseLogout}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              TransitionComponent: Fade,
            },
          }}
        >
          <Fade in={openLogout}>
            <Box sx={styleModal}>
              <AccountCircle style={{ fontSize: 100 }} />
              <Typography id="spring-modal-title" variant="h6" component="h2">
                Apakah anda yakin ingin keluar?
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="outlined"
                  onClick={handleCloseLogout}
                  sx={{ mr: 2, backgroundColor: "#FE8A01", color: "white" }}
                >
                  Tidak
                </Button>
                <Button variant="outlined" onClick={handleLogout}>
                  Ya
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
        <br></br>
        <Toolbar />

        {showError && (
          <Alert variant="filled" severity="error" sx={{ marginBottom: 5 }}>
            {msgError}
          </Alert>
        )}

        {/* Root container */}
        <RootContainer>
          {/* Employee selector */}
          <Box sx={{ minWidth: 300 }}>
            <FormControl fullWidth>
              <Typography>Pilih Karyawan *</Typography>
              <Autocomplete
                fullWidth
                options={
                  !employeeOptions
                    ? [{ label: "Loading...", id: 0 }]
                    : employeeOptions
                }
                renderInput={(params) => (
                  <TextField {...params} label="Employee" />
                )}
                onChange={handleEmployeeChange}
              />
            </FormControl>
          </Box>

          {/* Month selector */}
          <Grid container alignItems="center" justifyContent="center">
            <Grid item>
              <IconButton onClick={handlePreviousMonth}>
                <ChevronLeft />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h6">{`${currentMonthName} ${currentYear}`}</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleNextMonth}>
                <ChevronRight />
              </IconButton>
            </Grid>
          </Grid>

          {/* Pay detail table */}
          <Box sx={{ width: "100%" }}>
            {scheduledClockIn !== "" && offDay !== "" && (
              <Typography>
                Jam masuk: {scheduledClockIn} &nbsp;&nbsp;&nbsp; Jadwal Libur:
                Setiap {offDay}
              </Typography>
            )}
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer sx={{ maxHeight: 400 }}>
                <TextField
                  label="Search"
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  sx={{ mt: "5px", width: "50%" }}
                />
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {visibleRows?.map((pd, index) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={index}
                          sx={{
                            cursor: "pointer",
                            backgroundColor: decideRowColor(pd),
                          }}
                        >
                          <TableCell align="center">{pd?.date}</TableCell>
                          <TableCell align="center">
                            {pd?.clockIn || "00:00"}
                          </TableCell>
                          <TableCell align="center">
                            {pd?.clockOut || "00:00"}
                          </TableCell>
                          <TableCell align="center">
                            {pd?.hoursWorked || 0}
                          </TableCell>
                          <TableCell align="center">
                            {formatCurrency(pd?.basePay)}
                          </TableCell>
                          <TableCell align="center">
                            {formatCurrency(pd?.foodAllowance)}
                          </TableCell>
                          <TableCell align="center">
                            {formatCurrency(pd?.overtimePay)}
                          </TableCell>
                          <TableCell align="center">
                            {formatCurrency(pd?.offPay)}
                          </TableCell>
                          <TableCell align="center">
                            - {formatCurrency(pd?.lateDeduction)}
                          </TableCell>
                          <TableCell align="center">
                            {pd?.attendanceStatus}
                          </TableCell>
                          <TableCell align="center">
                            {formatCurrency(pd?.netPay)}
                          </TableCell>

                          {/* Edit employee button */}
                          <TableCell sx={{ display: "flex" }}>
                            <Tooltip title="edit">
                              <IconButton
                                onClick={() =>
                                  handleOpenEditEmployeeAttendance(pd)
                                }
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />

              {/* Monthly Pay Summary */}
              <Table>
                <TableRow
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "auto 5% 20%",
                    gridTemplateRows: "repeat(3, auto)",
                  }}
                >
                  <TableCell align="right">Gaji Pokok</TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">
                    {formatCurrency(payslip?.monthlyPayGross)}
                  </TableCell>
                </TableRow>
                {employeeDebitMonthlyAdditionalPay?.map((item, index) => {
                  return (
                    <TableRow
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "auto 5% 20%",
                        gridTemplateRows: "repeat(3, auto)",
                      }}
                    >
                      <TableCell align="right">{item?.name}</TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">
                        {formatCurrency(item?.amount)}
                        <Tooltip title="Delete">
                          <IconButton onClick={() => handleOpenDelete(item.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "auto 5% 20%",
                    gridTemplateRows: "repeat(3, auto)",
                  }}
                >
                  <TableCell align="right">Gaji Libur</TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">
                    +{formatCurrency(payslip?.offPay)}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "auto 5% 20%",
                    gridTemplateRows: "repeat(3, auto)",
                  }}
                >
                  <TableCell align="right">Bonus Lembur</TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">
                    {"+" + formatCurrency(payslip?.overtimePay)}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "auto 5% 20%",
                    gridTemplateRows: "repeat(3, auto)",
                  }}
                >
                  <TableCell align="right">Uang Makan</TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">
                    {"+" + formatCurrency(payslip?.foodAllowance)}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "auto 5% 20%",
                    gridTemplateRows: "repeat(3, auto)",
                  }}
                >
                  <TableCell align="right">Absen</TableCell>
                  <TableCell align="center">{payslip?.absentCount}</TableCell>
                  <TableCell align="center">
                    {"-" + formatCurrency(payslip?.absentDeduction)}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "auto 5% 20%",
                    gridTemplateRows: "repeat(3, auto)",
                  }}
                >
                  <TableCell align="right">Terlambat</TableCell>
                  <TableCell align="center">{payslip?.lateCount}</TableCell>
                  <TableCell align="center">
                    {"-" + formatCurrency(payslip?.lateDeduction)}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "auto 5% 20%",
                    gridTemplateRows: "repeat(3, auto)",
                  }}
                >
                  <TableCell align="right">Potongan</TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">
                    {"-" + formatCurrency(payslip?.netDeduction)}
                  </TableCell>
                </TableRow>
                {employeeCreditMonthlyAdditionalPay?.map((item, index) => {
                  return (
                    <TableRow
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "auto 5% 20%",
                        gridTemplateRows: "repeat(3, auto)",
                      }}
                    >
                      <TableCell align="right">{item?.name}</TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">
                        {"-" + formatCurrency(item?.amount)}
                        <Tooltip title="Delete">
                          <IconButton onClick={() => handleOpenDelete(item.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}

                <TableRow
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "auto 5% 20%",
                    gridTemplateRows: "repeat(3, auto)",
                  }}
                >
                  <TableCell align="right">

                      {/* Form for new transaction */}
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleAddTransaction();
                        }}
                        style={{
                          display: "flex",
                          alignItems: "right",
                          gap: "10px",
                          marginTop: "20px",
                        }}
                      >
                        <TextField
                          label="Nama"
                          name="name"
                          value={newTransaction.name}
                          onChange={handleInputChange}
                          sx={{ width: "150px", fontSize: "12px" }}
                          size="small"
                        />
                        <TextField
                          label="Jumlah"
                          name="amount"
                          value={newTransaction.amount}
                          onChange={handleInputChange}
                          sx={{ width: "100px", fontSize: "12px" }}
                          size="small"
                        />
                        <FormControl>
                          <InputLabel id="transaction-type-label">
                            Jenis
                          </InputLabel>
                          <Select
                            labelId="transaction-type-label"
                            name="type"
                            value={newTransaction.type}
                            onChange={handleInputChange}
                            sx={{ fontSize: "12px" }}
                          >
                            <MenuItem value="DEBIT">DEBIT</MenuItem>
                            <MenuItem value="CREDIT">CREDIT</MenuItem>
                          </Select>
                        </FormControl>
                        <Button
                          type="submit"
                          variant="contained"
                          disabled={loading}
                          sx={{ fontSize: "12px", padding: "5px 10px" }}
                        >
                          {loading ? "Adding..." : "Tambah Note"}
                        </Button>
                      </form>

                      {/* Error Message */}
                      {error && <Typography color="error">{error}</Typography>}

                      {/* Existing Summary
                      <Table>
                        {employeeDebitMonthlyAdditionalPay?.map(
                          (item, index) => (
                            <TableRow key={index}>
                              <TableCell align="right">{item?.name}</TableCell>
                              <TableCell align="center">
                                {formatCurrency(item?.amount)}
                              </TableCell>
                            </TableRow>
                          )
                        )}

                        {employeeCreditMonthlyAdditionalPay?.map(
                          (item, index) => (
                            <TableRow key={index}>
                              <TableCell align="right">{item?.name}</TableCell>
                              <TableCell align="center">
                                {"-" + formatCurrency(item?.amount)}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </Table> */}

                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "auto 5% 20%",
                    gridTemplateRows: "repeat(3, auto)",
                  }}
                >
                  <TableCell align="right">Total Gaji</TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">
                    {formatCurrency(salaryBalance)}
                  </TableCell>
                </TableRow>
              </Table>

              <Modal
                            aria-labelledby="spring-modal-title"
                            aria-describedby="spring-modal-description"
                            open={openDelete}
                            onClose={handleCloseDelete}
                            closeAfterTransition
                            slots={{ backdrop: Backdrop }}
                            slotProps={{
                              backdrop: {
                                TransitionComponent: Fade,
                              },
                            }}
                          >
                            <Fade in={openDelete}>
                              <Box sx={styleModal}>
                                <Typography
                                  id="spring-modal-title"
                                  variant="h6"
                                  component="h2"
                                >
                                  Apakah anda yakin ingin membuang data ini?
                                </Typography>
                                <Box sx={{ mt: 2 }}>
                                  <Button
                                    variant="outlined"
                                    onClick={handleCloseDelete}
                                  >
                                    Tidak
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    onClick={() => handleConfirmDelete()}
                                    sx={{
                                      ml: 2,
                                      backgroundColor: "#FE8A01",
                                      color: "white",
                                    }}
                                  >
                                    Ya
                                  </Button>
                                </Box>
                              </Box>
                            </Fade>
                          </Modal>

              {/* ini modal edit data karyawan */}
              <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={editAttendanceOpen}
                onClose={() => setEditAttendanceOpen(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                  backdrop: {
                    TransitionComponent: Fade,
                  },
                }}
              >
                <Fade in={editAttendanceOpen}>
                  <Box sx={styleModalBesar}>
                    <form>
                      <Grid container spacing={3}>
                        {/* Full Name */}
                        <Grid item xs={12}>
                          <Typography variant={"h5"}>
                            {attendanceDate}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            required
                            type={"time"}
                            label={"Clock In"}
                            value={attendanceClockIn}
                            onChange={(e) =>
                              setAttendanceClockIn(e.target.value)
                            }
                            onFocus={(e) =>
                              e.target.showPicker && e.target.showPicker()
                            }
                            sx={"color-scheme: dark;"}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            required
                            type={"time"}
                            label={"Clock Out"}
                            value={attendanceClockOut}
                            onChange={(e) =>
                              setAttendanceClockOut(e.target.value)
                            }
                            onFocus={(e) =>
                              e.target.showPicker && e.target.showPicker()
                            }
                            sx={"color-scheme: dark;"}
                          />
                        </Grid>
                        {/* <Grid item xs={12}>
                          <Autocomplete
                            fullWidth
                            options={
                              !attendanceStatusOptions
                                ? [{ label: "Loading...", id: 0 }]
                                : attendanceStatusOptions
                            }
                            renderInput={(params) => (
                              <TextField {...params} label="Keterangan" />
                            )}
                            onChange={(e, value) =>
                              setAttendanceStatus(value.label)
                            }
                          />
                        </Grid> */}
                        <Grid item xs={12}>
                          <Multiselect
                            options={
                              !attendanceStatusOptions
                                ? [{ label: "Loading...", id: 0 }]
                                : attendanceStatusOptions
                            }
                            selectedValues={selectedValues}
                            onSelect={onSelect}
                            onRemove={onRemove}
                            displayValue={"label"}
                            placeholder={
                              selectedValues.length > 0 ? "" : "Keterangan"
                            }
                            style={{
                              searchBox: {
                                height: "100%",
                                fontSize: "16px",
                                width: "100%",
                                paddingTop: "13px",
                                paddingLeft: "12px",
                                background: "white",
                                colors: "black",
                              },
                              chips: {
                                backgroundColor: "black",
                                color: "white",
                              },
                            }}
                            className="custom-multiselect"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            onClick={handleUpdateEmployeeAttendance}
                            fullWidth
                            style={{ backgroundColor: "black", color: "white" }}
                          >
                            Update
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Box>
                </Fade>
              </Modal>
            </Paper>
          </Box>

          {currentEmployee && currentEmployeePayDetail && (
            <PDFDownloadLink
              document={
                <SalarySlipPDF
                  employeeData={currentEmployee}
                  salaryData={currentEmployeePayDetail}
                />
              }
              fileName={salarySlipPDFFileName}
            >
              {({ loading }) => (
                <Button style={btnCetakSlipGaji}>
                  {loading ? "Generating PDF..." : "Cetak Slip Gaji"}
                </Button>
              )}
            </PDFDownloadLink>
          )}
        </RootContainer>
      </Box>
    </Box>
  );
}
