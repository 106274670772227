import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Navigation from "./navigation";
import { Alert, Button, Container, Grid, Typography } from "@mui/material";
import CustomerService from "../services/customer/customerService";
import ItemCard from "./components/itemCard/itemCard";
import TagService from "../services/manager/tagService";
import Footer from "./components/footer/footer";

const MoreItemsPage = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const cleanedBackendUrl = backendUrl.replace("/backend", "");
  const [ShowSuccess, setShowSuccess] = useState(false);
  const [MsgSuccess, setMsgSuccess] = useState("");
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState();
  const [itemTypeList, setItemTypeList] = useState([]);
  const [tagSecondaryList, setTagSecondaryList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [shownItem, setShownItem] = useState([]);
  const [title, setTitle] = useState([]);
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const navigate = useNavigate();
  const { tag } = useParams();

  const queryParams = new URLSearchParams(useLocation().search);
  const tagMainId = queryParams.get("tagMain");
  const tagSecondaryId = queryParams.get("tagSecondary");
  const showAll = queryParams.get("showAll");

  const getItemTypeList = async () => {
    try {
      const response = await CustomerService.getItemTypeList();
      if (response) {
        const data = response.data;
        setItemTypeList(data.itemTypeList);
      }
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  };

  const getAllTag = async () => {
    try {
      const response = await TagService.getTagList("secondary");
      if (response) {
        const data = response.data.tagModelList;
        setTagSecondaryList(data);

        let title = ""
        if (tagSecondaryId) {
          title = data.find(tag => tag.id === parseInt(tagSecondaryId)).title
          console.log(data)
        }
        setTitle(title)
      }
    } catch (error) {
      console.error(error);
      setMsgError("Error while fetching list tags");
      activateError();
    }
  };

  const activateSuccess = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 5000);
  };

  const activateError = () => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 5000);
  };

  async function fetchItems() {
    try {
      const responseItemList = await CustomerService.getListItem(
        tagMainId,
        tagSecondaryId
      );
      setItemList(responseItemList.data.itemList);
      setShownItem(responseItemList.data.itemList);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    console.log(tag);
    window.scrollTo(0, 0);
    fetchItems();
    getAllTag();
    getItemTypeList();

  }, []);

  useEffect(() => {
    if (selectedTag === "") {
      setShownItem(itemList);
    } else {
      // setShownItem(itemList.filter((item) => item.typeId === selectedType));
      setShownItem(itemList.filter(item => 
        item.tagSecondary.some(tag => tag.id === selectedTag)
      ))
    }
  }, [selectedTag]);

  useEffect(() => {
    if (selectedType === "") {
      setShownItem(itemList);
    } else {
      setShownItem(itemList.filter((item) => item.typeId === selectedType));
    }
  }, [selectedType])

  const btnPrimaryStyle = {
    borderRadius: "100px",
    width: "100%",
    backgroundColor: "#FE8A01",
    color: "white",
    padding: "5px",
  };

  const btnPrimaryOutlineStyle = {
    borderRadius: "100px",
    width: "100%",
    backgroundColor: "transparent",
    border: "1px solid #FE8A01",
    color: "#FE8A01",
    padding: "5px",
  };

  return (
    <>
      <Navigation />

      <Container component="main" sx={{ minHeight: "100vh" }}>
        {ShowSuccess && (
          <Alert variant="filled" severity="success" style={{ marginTop: 20 }}>
            {MsgSuccess}
          </Alert>
        )}
        {showError && (
          <Alert variant="filled" severity="error" style={{ marginTop: 20 }}>
            {msgError}
          </Alert>
        )}


        { showAll ? (
          <Grid container marginTop={5} spacing={1}>
            <Grid item md={3} sm={4} xs={6}>
              <Button style={selectedType === "" ? btnPrimaryStyle : btnPrimaryOutlineStyle}
                onClick={() => setSelectedType("")}>All</Button>
            </Grid>
            {
              itemTypeList.map(itemType =>
                <Grid item md={3} sm={4} xs={6}>
                  <Button style={selectedType === itemType.id ? btnPrimaryStyle : btnPrimaryOutlineStyle}
                    onClick={() => setSelectedType(itemType.id)}>{itemType.name}-{itemType.variant}</Button>
                </Grid>
              )
            }
          </Grid>
        ) : null }

        {/* {!tagSecondaryId ? (
          <Grid container marginTop={5} spacing={1}>
            <Grid item md={3} sm={4} xs={6}>
              <Button
                style={
                  selectedTag === "" ? btnPrimaryStyle : btnPrimaryOutlineStyle
                }
                onClick={() => setSelectedTag("")}
              >
                All
              </Button>
            </Grid>
            {tagSecondaryList.map((tag) => (
              <Grid item md={3} sm={4} xs={6}>
                <Button
                  style={
                    selectedTag === tag.id
                      ? btnPrimaryStyle
                      : btnPrimaryOutlineStyle
                  }
                  onClick={() => setSelectedTag(tag.id)}
                >
                  {tag.title}
                </Button>
              </Grid>
            ))}
          </Grid>
        ) : null} */}

        <Grid container marginTop={5} marginBottom={5}>
          <Grid item xs={12}>
            <Typography variant="h4" fontWeight="bold" color={"#FE8A01"} textTransform="uppercase">
              {title}
            </Typography>
          </Grid>
          {shownItem.map((item) => (
            <ItemCard key={item.id} item={item} />
          ))}

          {shownItem.length <= 0 && (
            <Grid item xs={12} textAlign="center" marginTop={3}>
              <Typography variant="h6" fontWeight="bold" color={"#FE8A01"}>
                No item found
              </Typography>
            </Grid>
          )}
        </Grid>
      </Container>

      <Footer />
    </>
  );
};

export default MoreItemsPage;
