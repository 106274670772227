import React from 'react';
import ImageSortableItem from './imageSortableItem';
import { Grid } from '@mui/material';

function ImageSortableList({ images, setImages, alt, deleteItem }) {

  const moveItem = (dragIndex, hoverIndex) => {
    const updatedItems = [...images];
    const [movedItem] = updatedItems.splice(dragIndex, 1);
    updatedItems.splice(hoverIndex, 0, movedItem);
    setImages(updatedItems);
  };

  return (
    <>
      <Grid container spacing={2} marginTop={0}>
          {
            images.map((image, index) => (
              <ImageSortableItem 
                key={image}
                image={image}
                index={index}
                alt={alt}
                moveItem={moveItem}
                deleteItem={deleteItem}
              />
            ))
          }
      </Grid>
    </>
  )
}

export default ImageSortableList;